import React, { /*lazy,*/ useState } from "react";
import { /*NavLink,*/ NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setUser } from "../../store/userSlice";
import logo_full from "../../assets/img/branding/logo_full.svg";
import notification_icon from "../../assets/img/svg/notification_icon.svg";
import hamburger_icon from "../../assets/img/svg/hamburger_icon.svg";
import hamburger_icon_manager from "../../assets/img/svg/hamburger_icon_manager.svg";
//import logo_infinity from "../../assets/img/branding/logo_infinity.svg";

import home from "../../assets/img/svg/dash_nav_icons/home_icon.svg";
import giveaway from "../../assets/img/svg/dash_nav_icons/giveaway_icon.svg";
import wallet from "../../assets/img/svg/dash_nav_icons/wallet_icon.svg";
import setting from "../../assets/img/svg/dash_nav_icons/setting_icon_grey.svg";
import profile_white from "../../assets/img/svg/dash_nav_icons/profile_white.svg";
import connections_white from "../../assets/img/svg/dash_nav_icons/connections_white.svg";
import sign_out from "../../assets/img/svg/settings_icons/logout.svg";
import cross_icon_grey from "../../assets/img/svg/cross_icon_grey.svg";
import avatar from "../../assets/img/example_vectors/user_avatar.png";
import { roles } from "../../meta";
import AvatarCircle from "../common/AvatarCircle";
import { auth } from "../../services/fb";
import { globalLogout } from "../../store/crossSliceFunctions";
import { navigateToHome } from "../../services/navigationFunctions";
import GiveawaySidebar from "./GiveawaySidebar";

//import HomeSidebar from "../managerDashboard/HomeSidebar";

function Navbar({ visibility }) {
  const initUserState = {
    auth: null,
    profile: {
      mobile: "",
      email: "",
      first_name: "",
      last_name: "",
    },
  };
  const { first_name, last_name, email, mobile, photoUrl } = useSelector(
    (state) => state.user.profile
  );
  const role = useSelector((state) => state.user.current_role);
  const self = useSelector((state) => state[`${role}s`]?.self ?? {});
  const alias_count = useSelector(
    (state) => Object.keys(state.aliases.self ?? {}).length
  );
  const [sideNavVisible, setSideNavVisible] = useState(false);
  const [currentSidebar, setCurrentSidebar] = useState("home");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboard_route =
    role === roles.manager ? "/dashboard/manager" : "/dashboard/influencer";

  const handleSignout = () => {
    sessionStorage.clear();
    dispatch(setUser(initUserState));
    globalLogout({ dispatch: dispatch });
    auth.signOut();
  };

  const SideBar = () => {
    switch (currentSidebar) {
      case "home":
        return null;
      case "giveaways":
        return <GiveawaySidebar alias_count={alias_count} />;
      default:
        break;
    }
  };

  const SideNavMobile = () => {
    const sidenav_options = [
      { type: "giveaways", src: giveaway, link: "/giveaways" },
      /* { type: "wallet", src: wallet, link: "/wallet" }, */
      {
        type: "profile",
        src: profile_white,
        link: "/dashboard/profile/edit",
        sidebar: false,
      },
    ];
    return (
      <div className="Side-nav-container-mobile">
        <div
          onClick={() => {
            setSideNavVisible(false);
          }}
          style={{ zIndex: "0" }}
          className={`Popup-close-overlay`}
        ></div>
        {Object.keys(self).length === 0 ? null : (
          <div
            style={{ position: "relative", zIndex: "1" }}
            className="Side-nav-mobile"
          >
            <div className="Manager-profile-card">
              <p
                onClick={() => setSideNavVisible(false)}
                className="text-link span-align-right"
              >
                Close
              </p>
              <div>
                <h3 className="text-primary">
                  <span className="span-text-bold">{first_name} </span>{" "}
                  {last_name}
                </h3>

                {/*      <p className="accent-text">{role}</p> */}
              </div>
            </div>
            <ul>
              <li
                onClick={() => {
                  setSideNavVisible(false);
                  navigate(dashboard_route);
                }}
                className="Menu-option"
              >
                Home
                <span className="Menu-icon">
                  <img src={home} alt="Home" />
                </span>
              </li>
              {sidenav_options.map((option) => {
                return (
                  <li
                    key={option.type}
                    onClick={() => {
                      if (option.sidebar === false) navigate(option.link);
                      else setCurrentSidebar(option.type);
                    }}
                    className="Menu-option"
                  >
                    {option.type}
                    <span className="Menu-icon">
                      <img src={option.src} alt="Home" />
                    </span>
                  </li>
                );
              })}
              <li
                onClick={handleSignout}
                className="Menu-option"
                style={{ color: "aqua" }}
              >
                Sign Out
                <span className="Menu-icon">
                  <img src={sign_out} alt="Setting" />
                </span>
              </li>
            </ul>
          </div>
        )}

        <SideBar />
      </div>
    );
  };

  const SideNav = () => {
    const sidebar_options = [
      { type: "giveaways", src: giveaway, link: "/giveaways" },
      /*  { type: "wallet", src: wallet, link: "/wallet" }, */
      {
        type: "Account",
        src: profile_white,
        link: "/account",
      },
      /*   {
        type: "connections",
        src: connections_white,
        link: "/dashboard/managers_list/add",
      }, */
    ];

    return (
      <div className={`${role === null ? "manager" : role}-sidenav`}>
        {Object.keys(self).length === 0 ||
        email.length === 0 ||
        mobile.length === 0 ? null : (
          <div className="Nav-container flex-column flex-align-center">
            <AvatarCircle
              first_name={first_name}
              last_name={last_name}
              styles="User-avatar-nav"
              photo={photoUrl}
            />
            {/* <div className="User-avatar">
              <img className="img-full" src={avatar} alt="avatar" />
            </div> */}
            <div className="flex-column flex-align-center">
              <NavLink
                to={dashboard_route}
                className={({ isActive }) => {
                  return `Nav-btn ${isActive ? "Nav-btn-active" : ""}`;
                }}
              >
                <div className={`Nav-btn`}>
                  <img src={home} alt={`home icon`} />
                  <p>Home</p>
                </div>
              </NavLink>

              {sidebar_options.map((option) => {
                return (
                  <NavLink
                    key={option.type}
                    to={option.link}
                    className={({ isActive }) => {
                      return `Nav-btn ${isActive ? "Nav-btn-active" : ""}`;
                    }}
                  >
                    <div className={`Nav-btn `}>
                      <img src={option.src} alt={`${option.type} icon`} />
                      <p>{option.type}</p>
                    </div>
                  </NavLink>
                );
              })}
              <NavLink
                onClick={handleSignout}
                to={"/"}
                className={({ isActive }) => {
                  return `Nav-btn`;
                }}
              >
                <div className={`Nav-btn`}>
                  <img src={sign_out} alt={`home icon`} />
                  <p>Sign Out</p>
                </div>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <SideNav />
      {sideNavVisible ? <SideNavMobile /> : null}
      <nav className={`Navbar Nav-top`}>
        <button
          style={{ borderRadius: "0" }}
          onClick={() => setSideNavVisible(true)}
          className="visibility-responsive"
        >
          <img src={hamburger_icon} alt="Menu" />
        </button>
        <div
          onClick={() => navigateToHome({ navigate: navigate })}
          className="Nav-logo"
        >
          <img src={logo_full} alt="socialpi logo" />
        </div>

        <button>
          <img src={notification_icon} alt="notification" />
        </button>
      </nav>
    </>
  );
}

export default Navbar;
