import "../../../assets/styles/dashboard/ManagerDashboard.css";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useNavigate } from "react-router-dom";
import React, { Suspense, useState } from "react";
import { useSelector } from "react-redux";

import Loader from "../../../components/common/Loader";
import Navbar from "../../../components/nav/Navbar";
import ErrorFallback, {
  myErrorHandler,
} from "../../../components/common/errors/ErrorFallback";
import InfluencerCard from "../../../components/managerDashboard/InfluencerCard";
import { modes, roles } from "../../../meta";
import {
  AddInfluencerWithCodePopup,
  AddManagerWithCodePopup,
  AddOptionsPopup,
} from "../../../components/common/Popups";

import add_circle_white from "../../../assets/img/svg/add_circle_white.svg";

function BrandLayout({ children }) {
  const Sidebar = () => {
    return (
      <>
        <div className="Sidebar-header"></div>
        <div className="Sidebar-menu"></div>
      </>
    );
  };

  const navigate = useNavigate();
  return (
    <div className="Dashboard-layout">
      <Navbar />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
        onReset={() => navigate(-1)}
      >
        <Suspense fallback={<Loader />}>
          <div className="Dashboard-container">
            <Sidebar />
            {children}
            <Outlet />
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default BrandLayout;
