import { useSelector } from "react-redux";

function AvatarCircle({ first_name, last_name, styles, photo }) {
  //const photo = useSelector((state) => state.user.profile.photoUrl ?? "");
  if (photo === "")
    return (
      <div className={`${styles}`}>
        <div>
          {first_name[0]}
          {last_name[0]}
        </div>
      </div>
    );
  else
    return (
      <img
        className="User-avatar-nav box-align-center-horizontal"
        src={photo}
        alt="Alias"
      />
    );
}

export default AvatarCircle;
