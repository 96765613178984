import React from "react";

export const myErrorHandler = (error, info) => {
  // Do something with the error
  // E.g. log to an error logging client here
  info.componentStack.replaceAll("@", "\n Path: ");
  const [empty, node, ...tree] = info.componentStack
    .replaceAll("@", "\nPath: ")
    .split(/\r?\n/);
  const [root, ...details] = [...tree];

  console.log(
    "%c\n\nDetailed Error Component Stack is : \n" +
      details.map((item) => {
        return item + "\n";
      }),
    "color: #00B8D4;"
  );

  console.log(
    "%cError Message : " + error.message,
    "background: #4d4d4d; color: #EA80FC; font-weight: bold; font-size: 15px;"
  );

  console.log(
    "%cIn component: " + empty + node + "\n" + root,
    "background: #4d4d4d; color: #EA80FC; font-weight: bold; font-size: 15px;"
  );
};

function ErrorFallback(props) {
  return (
    <div className="Error-fallback">
      <p>Something went wrong</p>
      <pre>{props.error.message}</pre>
      <button
        className="btn-large btn-primary"
        onClick={props.resetErrorBoundary}
      >
        Go Back
      </button>
    </div>
  );
}

export default ErrorFallback;
