import "../../../assets/styles/Registration.css";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, setUser, setUserProfile } from "../../../store/userSlice";
import { RecaptchaVerifier } from "firebase/auth";
import { OtpPopup } from "../../../components/common/Popups";
import tick_circle from "../../../assets/img/svg/tick_circle.svg";
import logo_full from "../../../assets/img/branding/logo_full.svg";
import btn_fb_sign_in from "../../../assets/img/sign_in_res/btn_fb_sign_in.png";
import GoogleSignIn from "../../../components/login/GoogleSignIn";
import Loader from "../../../components/common/Loader";

import { signUpWithPhone, auth } from "../../../services/fb";

const signInSchema = yup.object().shape({
  mobile: yup.string().length(10, "Invalid mobile number entered").required(),
  country_code: yup
    .string()
    .max(4, "Country Code can not be more than 4 characters")
    .matches(new RegExp(`[+][1-9]*$`), "Invalid country code!")
    .required(),
});

function BrandLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [phoneSignInState, setPhoneSignInState] = useState(null);
  const [isOtpPopupVisible, setOtpPopup] = useState(false);
  const [otpCounter, setOtpCounter] = useState(15);
  const [phoneNumber, setPhoneNumber] = useState("");
  const auth_session = sessionStorage.getItem(
    "firebase:authUser:AIzaSyAImpAj_7CH3r4smu2EZBZmDuqWOW5hz2Y:[DEFAULT]"
  );
  const userId = sessionStorage.getItem("userId");

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(signInSchema),
    defaultValues: { country_code: "+91" },
  });

  const onSubmit = async (data) => {
    setPhoneNumber(data.country_code + data.mobile);
    const res = await signUpWithPhone(
      data.country_code + data.mobile,
      setConfirmation,
      new RecaptchaVerifier(
        "sign-in-submit",
        {
          size: "invisible",
          callback: (response) => {
            console.log("Recaptcha verified!", response);
            setOtpPopup(true);
            setOtpStatus(true);
            return response;
          },
        },
        auth
      )
    );
    console.log("After returning confirmation object", res);
  };
  return (
    <>
      {confirmation !== null && isOtpPopupVisible ? (
        <OtpPopup
          phone={phoneNumber}
          setCodePopupState={setOtpPopup}
          confirmationResult={confirmation}
          setSignInState={setPhoneSignInState}
        />
      ) : null}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="Registration-layout-grid Container">
          <div className="Grid-register-infographic"></div>
          <div className="Grid-form-container">
            <div className="Signin-form-container">
              <div className="logo-header">
                <img src={logo_full} alt="logo" />
              </div>

              <div className="Login-options-container">
                <div className="Phone-signin-container">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="Phone-sign-in-form"
                  >
                    <div className="Signup-input">
                      <div className="Signin-heading">
                        <p>Continue with</p>
                      </div>
                    </div>

                    <div className="Signup-input">
                      <input
                        style={{ width: "4rem" }}
                        placeholder="Code"
                        {...register("country_code")}
                      />
                      &nbsp; &nbsp;
                      <input
                        style={{ width: "12rem" }}
                        placeholder="Mobile number"
                        {...register("mobile")}
                      />
                      <button
                        disabled={otpStatus}
                        id="sign-in-submit"
                        className="btn-primary-icon-round"
                      >
                        <span>
                          {otpStatus ? (
                            otpCounter
                          ) : (
                            <img src={tick_circle} alt="submit button" />
                          )}
                        </span>
                      </button>
                    </div>
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      {errors.mobile && errors.mobile.message}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      {errors.country_code && errors.country_code.message}
                    </p>
                  </form>
                </div>
                <div className="Divider-header">
                  <div className="Divider-line"></div>
                  or
                  <div className="Divider-line"></div>
                </div>
                <div className="Social-signin-container">
                  <GoogleSignIn setLoading={setLoading} />
                  {/* <button onClick={socialSignIn} className="google-signin-btn">
                  <img src={btn_google_sign_in} alt="google sign in" />{" "}
                  <span>Sign In with Google</span>
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BrandLogin;
