import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { giveaway_static_routes } from "../../routes";

function GiveawaySidebar({ alias_count }) {
  const draft_count = useSelector(
    (state) => Object.keys(state.giveaways.drafts ?? {}).length
  );
  const ended_count = useSelector(
    (state) => Object.keys(state.giveaways.ended ?? {}).length
  );
  const completed_count = useSelector(
    (state) => Object.keys(state.giveaways.completed ?? {}).length
  );
  const running_count = useSelector(
    (state) => Object.keys(state.giveaways.running ?? {}).length
  );
  const scheduled_count = useSelector(
    (state) => Object.keys(state.giveaways.scheduled ?? {}).length
  );

  const giveaways_options = [
    {
      type: "Create",
      link:
        alias_count === 0
          ? "/giveaways/create/new"
          : "/giveaways/create/select",
      tag: <span className="btn-tag tag-grey">+</span>,
    },
    {
      type: "Drafts",
      link: giveaway_static_routes.list_drafts,
      tag: <span className="btn-tag tag-yellow">{draft_count}</span>,
    },
    {
      type: "Ended",
      tag: <span className="btn-tag tag-yellow">{ended_count}</span>,
      link: giveaway_static_routes.list_ended,
    },
    {
      type: "Running",
      link: giveaway_static_routes.list_running,
      tag: <span className="btn-tag tag-green">{running_count}</span>,
    },

    /*  {
      type: "Completed",
      link: giveaway_static_routes.list_completed,
      tag: <span className="btn-tag tag-grey">{completed_count}</span>,
    }, */

    /* {
          type: "Scheduled",
          link: "",
          tag: <span className="btn-tag tag-red">{scheduled_count}</span>,
        }, */
    /*       {
          type: "Paused",
          link: "",
          tag: <span className="btn-tag tag-yellow">0</span>,
        }, */
  ];

  return (
    <div className="Sidebar-container">
      {giveaways_options.map((option) => {
        return (
          <NavLink to={option.link}>
            {option.type} {option.tag}
          </NavLink>
        );
      })}
      <a
        href="https://socialpi.ai/faq-creator/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Support{" "}
      </a>
    </div>
  );
}

export default GiveawaySidebar;
