import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
/*
  docRef:{
    auth:{uid:"",
        provider:"",
    },
    id:"",
    managers:{docRef: 
          {is_confirmed:bool,
        hiring_status:(employee/employer), 
        },
    },

    agency: {docRef: {is_confirmed:bool,
        hiring_status:(employee/employer), 
        },
    }, 
    aliases:[list of docRefs],
    wallet_summary:{},
    profile:{
        first_name:"",
        last_name:"",
        age_range: "",
        gender: "",
        email:"",
        mobile:"",
    }
  },
*/
const initialState = loadState().influencers;

export const influencerSlice = createSlice({
  name: "influencers",
  initialState,
  reducers: {
    resetInfluencers: () => {
      return {};
    },
    initManager: (state, action) => {
      state[action.payload.id].managers = [
        ...state[action.payload.id].managers,
        action.payload.manager,
      ];
    },
    addUnconfirmedManager: (state, action) => {
      const { id, data } = action.payload;
      state[id].unconfirmed_managers = {
        ...state[id].unconfirmed_managers,
        ...data,
      };
    },
    setInfluencers: (state, action) => {
      return { ...state, ...action.payload };
    },
    setAliases: (state, action) => {
      state[action.payload.influencer].aliases = [...action.payload.alias_list];
    },
  },
});

export const {
  resetInfluencers,
  setInfluencers,
  setAliases,
  initManager,
  addUnconfirmedManager,
} = influencerSlice.actions;
export default influencerSlice.reducer;
