import React from "react";
import dotloader from "../../assets/animations/dotloader.svg";

function LoaderDot({ variant }) {
  return (
    <div className={variant === "inline" ? "Loader-inline" : "Loader"}>
      <div className="Loader-img-dot">
        <img height="30px" src={dotloader} alt="loader svg" />
      </div>
    </div>
  );
}

export default LoaderDot;
