import { createSlice } from "@reduxjs/toolkit";
import { platforms } from "../meta";
import { logging } from "../services/consoleFunctions";

const initialState = {
  drafts: {},
  completed: {},
  running: {},
  scheduled: {},
  paused: {},
  ended: {},
};

export const giveawaySlice = createSlice({
  name: "giveaways",
  initialState,
  reducers: {
    setGiveaways: (state, action) => {
      return { ...state, ...action.payload };
    },
    addEndedGiveaway: (state, action) => {
      state.ended[action.payload.id] = { ...action.payload };
    },
    updateGiveawayStatus: (state, action) => {
      const { giveawayId, current_status, new_status } = action.payload;
      logging({
        from_file: "giveawaySlice.js",
        from_function: "Reducer: updateGiveawayStatus",
        data: action.payload,
        message: "Payload data",
      });
      let current_status_giveaways = { ...state[current_status] };
      state[current_status][giveawayId].status = new_status;
      state[new_status] = {
        ...state[new_status],
        [giveawayId]: { ...state[current_status][giveawayId] },
      };
      delete current_status_giveaways[giveawayId];
      state[current_status] = { ...current_status_giveaways };
    },

    initDraft: (state, action) => {
      const draft = {
        [action.payload.id]: {
          ...action.payload,
        },
      };
      state.drafts = { ...state.drafts, ...draft };
    },
    saveDraft: (state, action) => {
      state.drafts[action.payload.id] = {
        ...state.drafts[action.payload.id],
        ...action.payload.data,
      };
    },
    deleteDraft: (state, action) => {
      let temp = { ...state.drafts };
      delete temp[action.payload];
      state.drafts = { ...temp };
    },
    deleteAliasDrafts: (state, action) => {
      const { alias_id } = action.payload;
      let old_drafts = [...Object.values(state.drafts)];
      console.log(old_drafts);
      let new_drafts = {};
      old_drafts.map((draft) => {
        if (draft.alias !== alias_id) {
          return (new_drafts[draft.id] = { ...draft });
        }
        return null;
      });
      console.log(new_drafts);
      state.drafts = { ...new_drafts };
    },
    setGiveawayRunning: (state, action) => {
      const { id } = action.payload;
      let drafts = { ...state.drafts };
      delete drafts[id];
      state.drafts = { ...drafts };
      state.running = { ...state.running, id: { ...action.payload } };
    },
    addReward: (state, action) => {
      state.drafts[action.payload.id].rewards = {
        ...state.drafts[action.payload.id].rewards,
        ...action.payload.data,
      };
    },
    editReward: (state, action) => {
      const { id, reward_id, data } = action.payload;
      state.drafts[id].rewards[reward_id] = {
        ...state.drafts[id].rewards[reward_id],
        ...data,
      };
    },
    removeReward: (state, action) => {
      let rewards = { ...state.drafts[action.payload.id].rewards };
      delete rewards[action.payload.reward_id];
      state.drafts[action.payload.id].rewards = { ...rewards };
    },
    addPlatformTasks: (state, action) => {
      const { id, platform, tasks } = action.payload;
      state.drafts[id].tasks[platform] = {
        ...state.drafts[id].tasks[platform],
        ...tasks,
      };
    },
    removeTask: (state, action) => {
      const { id, platform, type, task_id } = action.payload;

      let tasks =
        platform === platforms.custom
          ? { ...state.drafts[id].tasks[platform] }
          : {
              ...state.drafts[id].tasks[platform][type],
            };
      delete tasks[task_id];
      if (platform === platforms.custom)
        state.drafts[id].tasks[platform] = { ...tasks };
      else
        state.drafts[id].tasks[platform][type] = {
          ...tasks,
        };
    },
    updateRewardWinners: (state, action) => {
      const { giveawayId, rewardId, winners, criteria } = action.payload;
      state.ended[giveawayId].rewards[rewardId].dist_details.winners = [
        ...winners,
      ];
      state.ended[giveawayId].rewards[rewardId].dist_details.status =
        "pending announcement";
      state.ended[giveawayId].rewards[rewardId].dist_details.criteria =
        criteria;
    },
    updateRewardStatus: (state, action) => {
      const { giveawayId, rewardId, status } = action.payload;
      state.ended[giveawayId].rewards[rewardId].dist_details.status = status;
    },
  },
});

export const {
  setGiveaways,
  addEndedGiveaway,
  updateGiveawayStatus,
  initDraft,
  saveDraft,
  deleteDraft,
  deleteAliasDrafts,
  addReward,
  editReward,
  removeReward,
  updateRewardWinners,
  updateRewardStatus,
  addPlatformTasks,
  removeTask,
  setGiveawayRunning,
} = giveawaySlice.actions;
export default giveawaySlice.reducer;
