import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  createAlias,
  createGiveawayDraft,
  discardAlias,
  saveAliasDetails,
} from "../../../../services/fb";
import DisplayPictureUpload from "../../../common/DisplayPictureUpload";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { roles } from "../../../../meta";
import { initDraft } from "../../../../store/giveawaySlice";
import { initAlias } from "../../../../store/aliasSlice";
import { useRef } from "react";
import GiveawayHeader from "../../../common/GiveawayHeader";
import avatar_placeholder from "../../../../assets/img/svg/avatar_placeholder.svg";
import { giveaway_static_routes } from "../../../../routes";

function StepNewUsername() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const _aliasDoc = useRef({ id: null });
  const _giveawayId = useRef(null);

  const [photoUrl, setPhotoUrl] = useState("");
  const [photoFolder, setPhotoFolder] = useState(null);

  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      alias: user.profile.first_name ?? "YourAwesomeUsername",
    },
  });

  const unloadListener = () => {
    if (_giveawayId.current === null) {
      console.log("discarding");
      discardAlias(_aliasDoc.current.id);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadListener);

    if (_aliasDoc.current.id === null)
      createAlias(user.id, {
        alias: user.profile.first_name ?? "YourAwesomeProfile",
      }).then((created_alias) => {
        if (created_alias.error) {
          alert(
            "Oh! It looks like you already have a giveaway profile. Redirecting you to profile selection."
          );
          navigate(giveaway_static_routes.create_select_alias);
          return 0;
        } else {
          console.log(created_alias);
          _aliasDoc.current = created_alias;
          setPhotoFolder(created_alias.id);
        }
      });
  });

  useEffect(() => {
    return () => {
      if (_giveawayId.current === null) {
        console.log("discarding");
        discardAlias(_aliasDoc.current.id);
        window.removeEventListener("beforeunload", unloadListener);
      }
    };
  }, []);

  const handleDiscard = async () => {
    await discardAlias(_aliasDoc.current.id);
    navigate("/dashboard/influencer");
  };

  const onUsernameSubmit = (data) => {
    const { alias } = data;
    const alias_doc = { ..._aliasDoc.current, name: alias, photo: photoUrl };
    saveAliasDetails(_aliasDoc.current.id, {
      name: alias,
      photo: photoUrl,
    }).then(() => {
      dispatch(initAlias({ influencer: "self", data: { ...alias_doc } }));
    });

    createGiveawayDraft({
      created_by: roles.influencer,
      creator: user.id,
      alias: alias_doc.id,
      influencer: alias_doc.influencer,
      alias_name: alias_doc.name,
      alias_photo: alias_doc.photo,
      managers: [],
    }).then((giveaway_doc) => {
      console.log(giveaway_doc);
      dispatch(
        initDraft({
          ...giveaway_doc,
        })
      );
      _giveawayId.current = giveaway_doc.id;
      navigate(`/giveaways/create/${giveaway_doc.id}/title_and_description`);
    });
  };

  useEffect(() => {
    console.log(photoFolder);
  }, [photoFolder]);
  return (
    <div className="Giveaway-create-container Giveaway-absolute">
      <GiveawayHeader disabled={true}>
        <div className="Header-content">
          <h3>
            Get started with your Giveaway! <br />
            <span className="span-text span-text-bold">Create a Profile</span>
          </h3>
        </div>
      </GiveawayHeader>
      <div className="Giveaway-create-form">
        <form id="NewAliasForm" onSubmit={handleSubmit(onUsernameSubmit)}>
          <DisplayPictureUpload
            styles={{
              parent: "width-100 text-align-center ",
              avatar:
                "Alias-avatar-img square-126 box-align-center text-align-center",
              image: "border-1-solid-pb10",
            }}
            photoUrl={photoUrl}
            dbUpdate={setPhotoUrl}
            folder_path={`profile_uploads/aliases/${photoFolder}/alias_photo`}
          />
          <div className="Giveaway-form-field">
            <label className="font-size-small fw-500 margin-bottom-small">
              Profile Name
            </label>
            <input
              type="text"
              autoComplete="off"
              placeholder="Your username"
              {...register("alias")}
              required
            />
            {/*  <p className="Input-note margin-top-small">
              This will be the name and picture visible to your audience when
              they visit your giveaway
            </p> */}
          </div>
          <div
            style={{
              textAlign: "left",
              marginTop: "24px",
              width: "100%",
              paddingLeft: "12px",
            }}
          >
            <p className="fw-500">Preview on giveaway cards</p>
          </div>
          <div className="Gc-alias-preview">
            <div className="Gc-alias-preview-header">
              {" "}
              <img
                className="Gc-alias-preview-image"
                alt="alias"
                src={photoUrl === "" ? avatar_placeholder : photoUrl}
              />
              <p>{useWatch({ control, name: "alias" })}</p>
            </div>
            <div className="Gc-alias-preview-content">
              <div className="Gc-alias-preview-banner">
                <div className="Gc-alias-preview-banner-overlay">
                  Your Giveaway Banner <br />
                  Will Appear Here
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="Giveaway-create-buttons">
          <button
            type="button"
            onClick={handleDiscard}
            className="btn-secondary-form btn-large"
          >
            Close
          </button>

          <button
            type="submit"
            form="NewAliasForm"
            className="btn-primary-form btn-large"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default StepNewUsername;
