export const dashboard_root = "/dashboard";
export const dashboard_static_routes = { home: dashboard_root + "/influencer" };

export const giveaways_root = "/giveaways";
export const giveaway_static_routes = {
  create_new_alias: giveaways_root + "/create/new",
  create_select_alias: giveaways_root + "/create/select",
  list_drafts: giveaways_root + "/list/drafts",
  list_running: giveaways_root + "/list/running",
  list_completed: giveaways_root + "/list/completed",
  list_ended: giveaways_root + "/list/ended",
};

export const giveaways_create_step = ({ giveaway_id, step }) => {
  return giveaways_root + "/create/" + giveaway_id + "/" + step;
};
