import "./App.css";
import "./assets/styles/global.css";
import "./assets/styles/Common.css";
import "./assets/styles/Dashboard.css";
import "./assets/styles/DesignSystem.css";
import "./assets/styles/BottomSheets.css";
import "./assets/styles/utility.css";
import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { roles } from "./meta";

import Loader from "./components/common/Loader";
//import Login from "./pages/Login";
import { auth, fetchData, findUserByAuth } from "./services/fb";
import { setManager } from "./store/managerSlice";
import { setInfluencers } from "./store/influencerSlice";
import { setAliases } from "./store/aliasSlice";
import { setGiveaways } from "./store/giveawaySlice";
import { logout, setUser } from "./store/userSlice";
import BrandLayout from "./pages/brands/layouts/BrandLayout";
import BrandLogin from "./pages/brands/login/BrandLogin";
import BrandProfileCreate from "./pages/brands/login/BrandProfileCreate";

import { onAuthStateChanged } from "firebase/auth";
import { setSessionRole, setSessionUserId } from "./services/sessionFunctions";
import { logging } from "./services/consoleFunctions";
import { usePrevious, useWindowHeight } from "./hooks/StyleHooks";
import StepNewUsername from "./components/giveaway/createGiveaways/decoupledAlias/StepNewUsername";
import AliasSelectionList from "./components/profile/AliasSelectionList";
import { setCampaigns } from "./store/sponsoredCampaignSlice";
import BottomSheet from "./components/bottom_sheets/BottomSheet";
import { SH2 } from "./components/design_system/text/SubHeading";
import { globalLogout } from "./store/crossSliceFunctions";

/*############## LAYOUTS ##############*/
const Layout = lazy(() => import("./pages/Layout"));
const ManagerLayout = lazy(() => import("./pages/layouts/ManagerLayout"));
//const InfluencerLayout = lazy(() => import("./pages/layouts/InfluencerLayout"));
const RegistrationLayout = lazy(() =>
  import("./pages/layouts/RegistrationLayout")
);
const DashboardLayout = lazy(() => import("./pages/layouts/DashboardLayout"));
const NestedRouteLayout = lazy(() =>
  import("./pages/layouts/NestedRouteLayout")
);

/*############## DASHBOARDS ############*/

const ManagerDashboard = lazy(() =>
  import("./pages/dashboards/ManagerDashboard")
);
const InfluencerDashboard = lazy(() =>
  import("./pages/dashboards/InfluencerDashboard")
);
const EmptyDashboard = lazy(() => import("./pages/dashboards/EmptyDashboard"));

/*############## GIVEAWAYS ############*/
const CreationDecoupled = lazy(() =>
  import("./pages/giveaways/CreationDecoupled")
);
const CreateGiveaway = lazy(() => import("./pages/giveaways/CreateGiveaway"));
const GiveawayIndex = lazy(() => import("./pages/giveaways/GiveawaysIndex"));
const GiveawayList = lazy(() => import("./pages/giveaways/GiveawayList"));
const GiveawayPreview = lazy(() => import("./pages/giveaways/GiveawayPreview"));
const DistributeRewards = lazy(() =>
  import("./pages/giveaways/DistributeRewards")
);
/*############## PROTECTED ROUTES ##############*/
const ProtectedRouteInfluencer = lazy(() =>
  import("./components/common/ProtectedRoute").then((module) => ({
    default: module.ProtectedRouteInfluencer,
  }))
);
const ProtectedRouteManager = lazy(() =>
  import("./components/common/ProtectedRoute").then((module) => ({
    default: module.ProtectedRouteManager,
  }))
);
const ProtectedRoute = lazy(() =>
  import("./components/common/ProtectedRoute").then((module) => ({
    default: module.ProtectedRoute,
  }))
);
const ProtectedRouteSelfAuth = lazy(() =>
  import("./components/common/ProtectedRoute").then((module) => ({
    default: module.ProtectedRouteSelfAuth,
  }))
);

/*############## PROFILE DETAILS AND LOGIN ##############*/
const GlobalOnboarding = lazy(() =>
  import("./pages/registration/onboarding/GlobalOnboarding")
);
const InfluencerOnboarding = lazy(() =>
  import("./pages/registration/onboarding/InfluencerOnboarding")
);
const ManagerOnboarding = lazy(() =>
  import("./pages/registration/onboarding/ManagerOnboarding")
);
const SignUp = lazy(() => import("./pages/registration/SignUp"));
const RoleSelection = lazy(() =>
  import("./components/dashboard/RoleSelection")
);
const Profile = lazy(() => import("./pages/registration/Profile"));
const InfluencerCreation = lazy(() =>
  import("./pages/registration/InfluencerCreation")
);
const ManagerCreation = lazy(() =>
  import("./pages/registration/ManagerCreation")
);
const ProfileIndex = lazy(() => import("./pages/profile/ProfileIndex"));
const Connections = lazy(() => import("./pages/profile/Connections"));
const ConnectionList = lazy(() =>
  import("./pages/registration/ConnectionList")
);
const AuthHandlerTwitter = lazy(() =>
  import("./pages/registration/AuthHandlerTwitter")
);
const AuthHandlerYoutube = lazy(() =>
  import("./pages/registration/AuthHandlerYoutube")
);
const InfluencerSelection = lazy(() =>
  import("./pages/registration/InfluencerSelection")
);
const AliasCreation = lazy(() => import("./pages/registration/AliasCreation"));
const Socials = lazy(() => import("./pages/registration/Socials"));
const PlatformDetails = lazy(() =>
  import("./pages/registration/PlatformDetails")
);

const GiveawayRoutes = () => {
  return (
    <Route path="giveaway" element={<NestedRouteLayout />}>
      <Route path="create/:step" element={<CreateGiveaway />} />
      <Route path="manage" element={<CreateGiveaway />} />
      <Route path="view" element={<CreateGiveaway />} />
    </Route>
  );
};

const AccountRoutes = () => {
  return (
    <Route path="account" element={<ProfileIndex />}>
      <Route path="profile/:mode" element={<Profile />} />
      <Route path="connections" element={<Connections />} />
    </Route>
  );
};

const RegistrationRoutes = (role) => {
  return (
    <>
      {role === roles.manager ? (
        <>
          <Route
            path="managed_influencers/:mode"
            element={<InfluencerCreation />}
          />
          <Route
            path="influencer_selection/:mode"
            element={<InfluencerSelection />}
          />
        </>
      ) : (
        <>
          <Route path="my_managers/:mode" element={<ManagerCreation />} />
          <Route path="managers_list/:mode" element={<ConnectionList />} />
        </>
      )}

      <Route path=":influencer/aliases/:mode" element={<AliasCreation />} />
      <Route path=":influencer/:alias/socials" element={<Socials />} />
      <Route
        path=":influencer/:alias/socials/platform_details/:platform/:mode"
        element={<PlatformDetails />}
      />
      <Route
        path=":influencer/:alias/socials/platform_details/:platform/:mode/:username"
        element={<PlatformDetails />}
      />
    </>
  );
};

function App() {
  const user_red = useSelector((state) => state.user);
  const blocked = useSelector(
    (state) => state.influencers.self?.account_status?.is_blocked ?? false
  );

  const authFB = auth;
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );
  window.addEventListener("resize", () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
  });

  useEffect(() => {
    onAuthStateChanged(authFB, (user) => {
      logging({
        from_file: "App.js",
        from_function: "useEffect onAuthStateChange",
        data: user,
      });
      if (user !== null) {
        const provider =
          user.providerData[0].providerId === "google.com" ? "email" : "mobile";
        const id =
          provider === "email"
            ? user.providerData[0].email
            : user.providerData[0].phoneNumber;
        findUserByAuth(provider, id).then((result) => {
          if (!result) {
            setLoading(false);
          }
          if (Object.keys(result).includes("id")) {
            setSessionUserId(result.id);
            setSessionRole(result.current_role);
            fetchData(result.id).then(
              ({ user, managers, influencers, aliases, giveaways }) => {
                logging({
                  from_file: "App.js",
                  from_function: "useEffect onAuthStateChange",
                  data: user,
                  message: "After fetching data from FB",
                });
                if (!user) {
                  sessionStorage.removeItem("userId");
                  navigate("/");
                } else if (user.default_role === null) {
                  sessionStorage.setItem("current_role", null);
                  console.log("Default role is null");
                  setLoading(false);
                  return 0;
                } else {
                  sessionStorage.setItem("current_role", user.default_role);
                  dispatch(setUser(user));
                  dispatch(
                    setCampaigns(influencers.self.registered_campaigns ?? {})
                  );
                  dispatch(setManager(managers));
                  dispatch(setInfluencers(influencers));
                  dispatch(setGiveaways(giveaways));
                  dispatch(setAliases(aliases));
                  setLoading(false);
                  if (
                    (user.default_role === roles.manager &&
                      Object.keys(managers).length === 0) ||
                    (user.default_role === roles.influencer &&
                      Object.keys(influencers).length === 0) ||
                    user.profile.mobile === "" ||
                    user.profile.email === ""
                  )
                    navigate("/dashboard/profile/new");
                  else if (
                    (user.default_role === roles.manager &&
                      managers.self.auth.unclaimed) ||
                    (user.default_role === roles.influencer &&
                      influencers.self.auth.unclaimed)
                  )
                    navigate("/dashboard/profile/new");
                }
              }
            );
          }
        });
      } else {
        setLoading(false);
        //navigate("/");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("current_role");
      }
    });
  }, [authFB]);

  /*************************************************************
  Effect for changing accent color if detected role is manager 
*************************************************************/

  const RegistrationWrapper = () => {
    return (
      <ProtectedRoute isLoading={isLoading}>
        <Suspense fallback={<Loader />}>
          <RegistrationLayout />
        </Suspense>
      </ProtectedRoute>
    );
  };

  /*********************************************************
  Suspense and Protected Wrappers for different layout files

  ProtectedRoute = accesible only when logged in 
  else redirect to log in screen

  ProtectedRouteSelfAuth = accessible only when user selected
  a role after sign up and filled profile form
  else take to Profile Form

  ProtectedRouterManager = routes accesible only when role -> manager
  else give popup of access denied
  
  ProtectedRouterInfluencer = routes accesible only when role -> influencer
  else give popup of access denied
**********************************************************/
  const DashboardWrapper = () => {
    return (
      <ProtectedRoute isLoading={isLoading}>
        <ProtectedRouteSelfAuth>
          <Suspense fallback={<Loader />}>
            <DashboardLayout />
          </Suspense>
        </ProtectedRouteSelfAuth>
      </ProtectedRoute>
    );
  };

  const ManagerWrapper = () => {
    return (
      <ProtectedRouteManager isLoading={isLoading}>
        <Suspense fallback={<Loader />}>
          <ManagerDashboard />
        </Suspense>
      </ProtectedRouteManager>
    );
  };

  const InfluencerWrapper = () => {
    return (
      <ProtectedRouteInfluencer isLoading={isLoading}>
        <Suspense fallback={<Loader />}>
          <InfluencerDashboard />
        </Suspense>
      </ProtectedRouteInfluencer>
    );
  };

  const GiveawaWrapper = () => {
    return (
      <ProtectedRoute isLoading={isLoading}>
        <Suspense fallback={<Loader />}>
          <GiveawayIndex />
        </Suspense>
      </ProtectedRoute>
    );
  };

  if (isLoading) return <Loader />;
  if (blocked)
    return (
      <>
        <BottomSheet
          _setBottomSheetVisible={() => {
            sessionStorage.clear();
            dispatch(logout());
            globalLogout({ dispatch: dispatch });
            navigate("/");
            auth.signOut();
          }}
          _bottomSheetVisible={true}
          title={"Oops!"}
        >
          <SH2>
            Looks like your account has been{" "}
            <span className="fw-500">blocked due to your past activities</span>.
            If you think there has been a mistake please reach out to us at:
          </SH2>
          <SH2 styles={{ color: "#0d9152" }} classes="fw-500 mt-12">
            support@socialpi.ai
          </SH2>
        </BottomSheet>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<GlobalOnboarding />} />
          </Route>
        </Routes>
      </>
    );
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/*  <Route index element={<Login isAppLoading={isLoading} />} /> */}
        <Route index element={<GlobalOnboarding />} />
        {/* <Route path="creator_login" element={<InfluencerOnboarding />} />
        <Route path="manager_login" element={<ManagerOnboarding />} /> */}
        <Route path="/auth_handler/twitter" element={<AuthHandlerTwitter />} />
        <Route path="/auth_handler/youtube" element={<AuthHandlerYoutube />} />
        <Route path="brand" element={<Outlet />}>
          <Route path="login" element={<BrandLogin />} />
          <Route path="profile/create" element={<BrandProfileCreate />} />
          <Route path="dashboard" element={<BrandLayout />} />
        </Route>
        <Route path=":userid" element={<DashboardWrapper />}>
          <Route path="dashboard" element={<ManagerDashboard />} />
        </Route>

        <Route path="giveaways" element={<GiveawaWrapper />}>
          <Route path="create/new" element={<StepNewUsername />} />
          <Route path="create/select" element={<AliasSelectionList />} />
          <Route
            path="create/:giveaway_id/:step"
            element={<CreateGiveaway />}
          />
          <Route path="list/:category" element={<GiveawayList />} />
          <Route path=":category/:id" element={<GiveawayPreview />} />
          <Route path="ended/:id" element={<DistributeRewards />} />
        </Route>
        {AccountRoutes()}
        <Route
          path="dashboard/role_selection"
          element={
            isLoading ? (
              <Loader />
            ) : (
              <ProtectedRoute>
                <DashboardLayout>
                  <RoleSelection />
                </DashboardLayout>
              </ProtectedRoute>
            )
          }
        />

        <Route
          path="dashboard/profile/:mode"
          element={
            isLoading ? (
              <Loader />
            ) : (
              <ProtectedRoute>
                <DashboardLayout>
                  <Profile />
                </DashboardLayout>
              </ProtectedRoute>
            )
          }
        />
        <Route path="dashboard" element={<DashboardWrapper />}>
          {RegistrationRoutes(user_red.current_role)}
          <Route path="manager" element={<ManagerWrapper />} />
          <Route path="influencer" element={<InfluencerWrapper />} />
        </Route>

        <Route path="login" element={<RegistrationWrapper />}>
          <Route index element={<SignUp />} />
          {RegistrationRoutes(user_red.current_role)}
        </Route>

        <Route path="manager" element={<ManagerWrapper />}>
          <Route path="dashboard/:variant" element={<EmptyDashboard />} />
          <Route path="home" element={<ManagerDashboard />} />
          {GiveawayRoutes()}
        </Route>

        <Route path="influencer" element={<Outlet />}>
          <Route path="home" element={<ManagerDashboard />} />
          {GiveawayRoutes()}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
