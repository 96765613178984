import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import managerReducer from "./managerSlice";
import influencerReducer from "./influencerSlice";
import aliasReducer from "./aliasSlice";
import giveawayReducer from "./giveawaySlice";
import sponsoredCampaignReducer from "./sponsoredCampaignSlice";
import { env } from "../meta";

const rootReducer = {
  user: userReducer,
  managers: managerReducer,
  influencers: influencerReducer,
  aliases: aliasReducer,
  giveaways: giveawayReducer,
  sponsoredCampaigns: sponsoredCampaignReducer,
};

export const store = configureStore({
  reducer: { ...rootReducer },
  devTools: env === "development" ? true : false,
});
