import Lottie from "lottie-react";
import React from "react";
import loader_white from "../../assets/animations/loader_white.json";

function Loader() {
  return (
    <div id="OCLoader" className="Loader">
      <div className="Loader-img Loader-animation">
        <Lottie animationData={loader_white} />
        {/* <img height="150px" src={loader} alt="loader svg" /> */}
      </div>
    </div>
  );
}

export default Loader;
