import { SVGIcon } from "../../assets/img/svg/res";
import { icon_sizes, icon_stroke_width } from "../../assets/styles/constants";

export function IconSmall({ icon, fill = "none", stroke_color = "#1f1f1f" }) {
  return (
    <SVGIcon
      icon={icon}
      size={icon_sizes.small}
      fill={fill}
      stroke_color={stroke_color}
      stroke_width={icon_stroke_width.small}
    />
  );
}

export function IconMedium({ icon, fill = "none", stroke_color = "#1f1f1f" }) {
  return (
    <SVGIcon
      icon={icon}
      size={icon_sizes.medium}
      fill={fill}
      stroke_color={stroke_color}
      stroke_width={icon_stroke_width.medium}
    />
  );
}

export function IconLarge({ icon, fill = "none", stroke_color = "#1f1f1f" }) {
  return (
    <SVGIcon
      icon={icon}
      size={icon_sizes.large}
      fill={fill}
      stroke_color={stroke_color}
      stroke_width={icon_stroke_width.large}
    />
  );
}
