const setSessionUserId = (id) => {
  sessionStorage.setItem("userId", id);
};

const setSessionRole = (role) => {
  sessionStorage.setItem("current_role", role);
};

const removeSessionUserId = () => {
  sessionStorage.removeItem("userId");
};

const getSessionAuth = () => {
  return sessionStorage.getItem(
    "firebase:authUser:AIzaSyAImpAj_7CH3r4smu2EZBZmDuqWOW5hz2Y:[DEFAULT]"
  );
};

const getSessionUserId = () => {
  return sessionStorage.getItem("userId");
};

export {
  setSessionUserId,
  setSessionRole,
  removeSessionUserId,
  getSessionAuth,
  getSessionUserId,
};
