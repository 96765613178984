import { svg_icons } from "../../styles/constants";
import { ReactComponent as IconTickRound } from "./icon_tick_round.svg";
import { ReactComponent as IconCross } from "./cross_icon.svg";
import { ReactComponent as IconArrowRight } from "./icon_arrow_right.svg";
import { ReactComponent as EyePreview } from "./eye_icon.svg";
import { ReactComponent as TwinStars } from "./twin_stars.svg";

export function SVGIcon({
  icon,
  size = "18",
  fill = "none",
  stroke_color = "#1f1f1f",
  stroke_width = 1.6,
}) {
  const props = {
    fill: fill,
    stroke: stroke_color,
    strokeWidth: stroke_width,
    height: size,
    width: size,
  };
  switch (icon) {
    case svg_icons.round_tick:
      return <IconTickRound {...props} />;
    case svg_icons.cross_icon:
      return <IconCross {...props} />;
    case svg_icons.arrow_right:
      return <IconArrowRight {...props} />;
    case svg_icons.eye_icon:
      return <EyePreview {...props} />;
    case svg_icons.twin_stars:
      return <TwinStars {...props} />;
    default:
      break;
  }
}
