import React /*useState*/ from "react";
import {
  fetchData,
  getUnconfirmedInfluencers,
  getUnconfirmedManagers,
  signUpWithGoogle,
} from "../../services/fb";
import { useDispatch /*useSelector*/ } from "react-redux";
import { setUser } from "../../store/userSlice";
import { setManager } from "../../store/managerSlice";
import { setInfluencers } from "../../store/influencerSlice";
import { setAliases } from "../../store/aliasSlice";
import { useNavigate } from "react-router-dom";

import btn_google_sign_in from "../../assets/img/sign_in_res/google_sign_in.svg";
import { modes, roles } from "../../meta";
import { setGiveaways } from "../../store/giveawaySlice";
import { logging } from "../../services/consoleFunctions";

function GoogleSignIn({ setLoading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /*
  const user = usesetOnboardState] = useState({
    profile: false,
    influencers: false,Selector((state) => state.user);
  const influencers = useSelector((state) => state.influencers);
  const aliases = useSelector((state) => state.aliases);
  const [onboardState, 
    aliases: false,
  });
 */
  const loginWithGoogle = () => {
    setLoading(true);
    const result = signUpWithGoogle();
    result
      .then(async ({ user, isNew }) => {
        console.log(user);
        sessionStorage.setItem("userId", user.id);
        sessionStorage.setItem("current_role", user.default_role);
        if (isNew || user.default_role === null) {
          dispatch(setUser(user));
          logging({
            from_file: "GoogleSignIn.js",
            from_function: "loginWithGoogle",
            data: user,
            message: "After new sign up is detected",
          });
          navigate("/dashboard/role_selection");
          return 0;
        } else {
          await fetchData(user.id).then(
            ({ user, managers, influencers, giveaways, aliases }) => {
              dispatch(setUser(user));
              dispatch(setManager(managers));
              dispatch(setInfluencers(influencers));
              dispatch(setAliases(aliases));
              dispatch(setGiveaways(giveaways));
              if (
                (user.default_role === roles.manager &&
                  Object.keys(managers).length === 0) ||
                (user.default_role === roles.influencer &&
                  Object.keys(influencers).length === 0)
              )
                navigate("/dashboard/profile/create");
              else if (
                (user.default_role === roles.manager &&
                  managers.self.auth.unclaimed) ||
                (user.default_role === roles.influencer &&
                  influencers.self.auth.unclaimed)
              )
                navigate("/dashboard/profile/create");
            }
          );
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <button onClick={loginWithGoogle} className="google-signin-btn">
        <img src={btn_google_sign_in} alt="google sign in" />
        <span>Sign In with Google</span>
      </button>
    </>
  );
}

export default GoogleSignIn;
