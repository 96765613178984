import { is_viewport_mobile } from "./common_style_utility.js";

/****************************
##############################
********* SVG RELATED ********
##############################
*****************************/
export const svg_icons = {
  round_tick: "round_tick",
  cross_icon: "cross_icon",
  arrow_right: "arrow_right",
  twin_stars: "twin_stars",
  eye_icon: "eye_icon",
};

export const icon_sizes = {
  small: is_viewport_mobile ? 18 : 20,
  medium: is_viewport_mobile ? 20 : 22,
  large: is_viewport_mobile ? 24 : 26,
};

export const icon_stroke_width = {
  small: 1.6,
  medium: 1.8,
  large: 2,
};

export const icon_fill_types = {
  stroke_only: "stroke_only",
  fill_only: "fill_only",
  both: "both",
};

export const button_classes = {
  btn_primary: "Btn-primary",
  btn_secondary: "Btn-secondary",
  btn_tertiary: "Btn-tertiary",
};
