import { useDispatch, useSelector } from "react-redux";
import avatar_placeholder from "../../assets/img/svg/avatar_placeholder.svg";
import { header_variants, roles } from "../../meta";
import { createGiveawayDraft } from "../../services/fb";
import { useNavigate } from "react-router-dom";
import { initDraft } from "../../store/giveawaySlice";
import { useEffect, useState } from "react";
import GiveawayHeader from "../common/GiveawayHeader";

function AliasSelectionList(
  {
    /*  alias_list,
  selected_alias,
  handleSelect,
  user, */
  }
) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const alias_list = useSelector((state) => Object.values(state.aliases.self));
  const userId = useSelector((state) => state.user.id);
  const [_aliasDoc, _setAliasDoc] = useState(null);

  useEffect(() => {
    if (_aliasDoc === null && alias_list.length !== 0)
      _setAliasDoc(alias_list[0]);
  }, [_aliasDoc, alias_list]);

  const handleSelect = ({ alias }) => {
    _setAliasDoc(alias);
  };

  const handleClose = () => {
    navigate("/dashboard/influencer");
  };
  const handleContinue = () => {
    createGiveawayDraft({
      created_by: roles.influencer,
      creator: userId,
      alias: _aliasDoc.id,
      influencer: _aliasDoc.influencer,
      alias_name: _aliasDoc.name,
      alias_photo: _aliasDoc.photo,
      managers: [],
    }).then((giveaway) => {
      dispatch(
        initDraft({
          ...giveaway,
        })
      );
      navigate(`/giveaways/create/${giveaway.id}/title_and_description`);
    });
  };
  const selected_style = {
    outline: "2px solid var(--color-accent)",
    boxShadow: "0px 6px 10px 2px var(--color-primary-black-20)",
  };

  return (
    <div className="Giveaway-create-container Giveaway-absolute">
      <GiveawayHeader
        variant={header_variants.large}
        current_step={1}
        disabled={true}
      >
        <div className="Header-content">
          <h3>
            Choose your <br />
            <span className="span-text span-text-bold">
              Profile for this giveaway
            </span>
          </h3>
        </div>
      </GiveawayHeader>
      <div
        style={{
          borderTop: "solid 1px var(--color-input-outline-active)",
          gridArea: "form",
          height: "100%",
        }}
        className="width-100 flex-row flex-justify-space-evenly flex-align-center flex-wrap"
      >
        {alias_list.map((alias) => {
          return (
            <div
              key={alias.id}
              onClick={() => handleSelect({ alias: alias })}
              className="Influencer-thumbnail"
              style={{
                cursor: "pointer",
              }}
            >
              <div style={{ borderRadius: "100%" }} className="Avatar-img">
                {" "}
                <img
                  style={_aliasDoc?.id === alias.id ? selected_style : {}}
                  src={alias.photo === "" ? avatar_placeholder : alias.photo}
                  alt="alias"
                  className="Influencer-thumbnail-img"
                />
              </div>

              <p className={_aliasDoc?.id === alias.id ? "accent-text" : ""}>
                {alias.name}
              </p>
            </div>
          );
        })}
        <div className="Giveaway-create-buttons">
          <button
            onClick={handleClose}
            className="btn-secondary-form btn-large"
          >
            Close
          </button>

          <button
            onClick={handleContinue}
            className="Btn Btn-text Btn-text-medium btn-primary-form btn-large"
          >
            Continue
          </button>
        </div>
      </div>{" "}
    </div>
  );
}

export default AliasSelectionList;
