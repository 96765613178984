const userState = {
  auth: null,
  profile: { first_name: "", last_name: "", email: "", mobile: "" },
  default_role: null,
  current_role: null,
  onboard_state: {
    initial_profile: false,
    billing_details: false,
    alias_details: false,
  },
};

const managerState = {};
const influencerState = {};
const aliasState = {};

const initState = {
  user: userState,
  managers: managerState,
  influencers: influencerState,
  aliases: aliasState,
};

export const saveState = (state) => {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem("appState", serialState);
  } catch (err) {
    console.log(err);
  }
};

export const loadState = () => {
  try {
    const serialState = localStorage.getItem("appState");
    if (serialState === null) {
      return initState;
    }

    return {
      user: JSON.parse(serialState).user,
      managers: managerState,
      influencers: influencerState,
      aliases: aliasState,
    };
  } catch (err) {
    console.log(err);
  }
};
