import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
//import { storage } from "../../services/fb";
import cross_icon_grey from "../../assets/img/svg/cross_icon_grey.svg";
import cross_icon_red from "../../assets/img/svg/cross_icon_red.svg";
import WhatsAppButtonGreenLarge from "../../assets/img/svg/WhatsAppButtonGreenLarge.svg";
import { modes, roles } from "../../meta";
import {
  confirmInfluencer,
  confirmManager,
  uploadImageToFirebase,
} from "../../services/fb";
import { initManager, setInfluencers } from "../../store/influencerSlice";
import { initInfluencer, setManager } from "../../store/managerSlice";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import LoaderDot from "./LoaderDot";

function Popup({ children, styles, setPopup, close_button = true }) {
  useEffect(() => {
    if (close_button) {
      try {
        const content_container =
          document.getElementsByClassName("Popup-header")[0];

        const cross_button = document.createElement("span");
        cross_button.setAttribute("width", "20px");
        cross_button.setAttribute("id", "PopupCloseButton");
        cross_button.setAttribute(
          "class",
          "span-icon-30 span-align-right cursor-pointer"
        );
        cross_button.addEventListener("click", () => {
          setPopup(false);
        });
        const button_image = document.createElement("img");
        button_image.setAttribute("src", cross_icon_grey);
        button_image.setAttribute("alt", "closer");

        cross_button.appendChild(button_image);
        content_container?.appendChild(cross_button);
      } catch (err) {
        console.log(err.message);
      }
    }
  });
  useEffect(() => () => {
    if (close_button) {
      const cross_button = document.getElementById("PopupCloseButton");
      cross_button?.remove();
    }
  });
  return (
    <div className="Popup-container">
      <div
        onClick={() => {
          if (!close_button) return 0;
          else setPopup(false);
        }}
        className={`Popup-close-overlay`}
      ></div>
      {close_button === "outside" ? (
        <div className={`Popup-header-empty`}>
          <button className="Close-popup-round">
            <img src={cross_icon_red} alt="Close Popup" />
          </button>{" "}
        </div>
      ) : null}
      <div id="PopupBox" className={`Popup-box ${styles}`}>
        {children}
      </div>
    </div>
  );
}

function UploadImagePopup({
  setParentPopupState,
  folder_path,
  setImage,
  variant,
  size,
  key,
  dimension,
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: { image: [], url: "" },
  });
  const [progress, setprogress] = useState(0);
  const default_preview =
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Fpreset_preview.svg?alt=media&token=42c56ca1-b8a6-47ff-b563-32254827b9f7";
  const [preview, setPreview] = useState(default_preview);
  const [fileName, setFileName] = useState("");
  const reader = useMemo(() => new FileReader(), []);
  const msg_size = size / 1024;

  const onSubmit = async (data) => {
    clearErrors();
    if (data.image.length === 0) {
      setError("image", { message: "Upload a file before proceeding!" });
      return 0;
    }
    const accepted_formats = ["jpg", "jpeg", "png", "gif"];
    const file = data.image[0];
    const file_size = file.size / 1048576;
    const extension = file.type.split("/")[1];
    if (accepted_formats.includes(extension) && file_size < msg_size)
      await uploadImageToFirebase(file, folder_path, setprogress).then(
        (url) => {
          setImage(url);
          setParentPopupState(false);
        }
      );
    else if (!accepted_formats.includes(extension)) {
      setError("image", { message: "Invalid File Type" });
      setFileName("");
    } else {
      setError("image", {
        message: "File should be less than " + msg_size + "MB",
      });
      setFileName("");
    }
  };

  useEffect(() => {
    var preview = document.getElementById("bannerPreview");
    reader.addEventListener(
      "load",
      () => {
        preview.src = reader.result;
      },
      false
    );
  }, [reader]);

  return (
    <div className="Popup-container">
      <div className="Popup-box">
        <div className="Popup-header">
          <p>Upload Image</p>
          <button onClick={() => setParentPopupState(false)}>
            <img src={cross_icon_grey} alt="close popup" />
          </button>
          <br />
        </div>
        <div className="Popup-content ">
          {/*  <div className="Upload-option">
            <input
              id="urlUpload"
              onInput={() => {
                setValue("image", []);
                setFileName("");
                setPreview(document.getElementById("urlUpload").value);
              }}
              type="text"
              placeholder="Paste an image URL"
              {...register("url")}
            />
          </div> */}
          <div>
            {/*  <h3
              style={{ textAlign: "center", margin: "1rem 0" }}
              className="accent-text text-bold"
            >
              Or
            </h3>{" "} */}
            <div
              className={`text-align-center ${
                variant === "mini"
                  ? "Popup-uploaded-preview-mini"
                  : variant === "avatar"
                  ? "Avatar-upload-preview"
                  : variant === "square"
                  ? "Popup-uploaded-preview-square"
                  : "Popup-uploaded-preview"
              }`}
            >
              <img id="bannerPreview" src={preview} alt="banner preview" />
            </div>
            {errors.image && errors.image.message ? (
              <p className="error-text">{errors.image.message}</p>
            ) : null}
          </div>
          <form id="imageUpload">
            <div className="Choose-file-option">
              <br />
              {fileName.length === 0 ? null : (
                <div>
                  <button
                    style={{ position: "relative" }}
                    className="Btn Btn-text Btn-text-medium btn-full btn-primary"
                    type="button"
                    onClick={() => handleSubmit(onSubmit)()}
                  >
                    <div
                      className="Upload-progress"
                      style={{
                        width: `${progress}%`,
                      }}
                    ></div>
                    {
                      progress === 0 ? (
                        <p>Upload</p>
                      ) : null /* progress == 100 ? (
                      <p className="Upload-progress-text">Done!</p>
                    ) : (
                      <p className="Upload-progress-text">
                        Uploading... &nbsp;
                        <span className={`Progress-counter`}>{progress}%</span>
                      </p>
                    ) */
                    }
                  </button>
                </div>
              )}
              {fileName.length !== 0 ? null : (
                <label
                  style={{
                    padding: "0.75rem 0.5rem",
                    borderRadius: "2rem",
                    display: "inline-block",
                    width: "95%",
                  }}
                  htmlFor="upload"
                  className="Btn Btn-text Btn-text-medium btn-primary lil-padding brd-rad-half cursor-pointer"
                >
                  Upload From Device
                </label>
              )}
              <p>
                <br />
                <span className="span-text-note">Note: </span>Image less than{" "}
                <span className="text-bold">{msg_size}MB</span>. Recommended -
                <span className="text-bold">{dimension}</span>
                <br />
                <span className="text-bold">
                  Image should be in JPG or PNG format
                </span>
              </p>
              <input
                onInput={() => {
                  setFileName(document.getElementById("upload").files[0]?.name);
                  reader.readAsDataURL(
                    document.getElementById("upload").files[0]
                  );
                  setValue("url", "");
                  setPreview("");
                }}
                id="upload"
                type="file"
                {...register("image")}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function CodeCreatePopup(code) {
  return (
    <div className="Popup">
      <div className="Code-create">
        <p>{code}</p>
      </div>
    </div>
  );
}

function AddOptionsPopup({ setCodePopupState, setParentPopupState, role }) {
  const navigate = useNavigate();
  return (
    <div className="Popup-container">
      <div className="Popup-box">
        <div className="Popup-header">
          <p>Add your {role === roles.manager ? "Influencer" : "Manager"}</p>
          <button onClick={() => setParentPopupState(false)}>
            <img src={cross_icon_grey} alt="close popup" />
          </button>
        </div>
        <div className="Popup-content text-align-center">
          <span className="accent-text text-bold">Option 1</span>

          <p>
            <br />
            You can connect with your{" "}
            {role === roles.manager ? "Influencer" : "Manager"} by{" "}
            <span className="span-text-bold">entering the code</span> that was
            shared by them with you.
          </p>
          <br />
          <button
            className="Btn Btn-text Btn-text-medium btn-full btn-primary"
            onClick={() => {
              setCodePopupState(true);
              setParentPopupState(false);
            }}
          >
            Connect Via Code
          </button>
          <br />
          <div className="Popup-header"></div>
          <br />
          <span className="accent-text text-bold">Option 2</span>
          <p>
            <br />
            You can Generate your one time code and{" "}
            <span className="span-text-bold">share it with them</span>, so they
            can use it to add you.
          </p>
          <br />
          <button
            className="Btn Btn-text Btn-text-medium btn-full btn-primary"
            onClick={() => {
              navigate(
                role === roles.manager
                  ? `/dashboard/managed_influencers/${modes.add}`
                  : `/dashboard/my_managers/${modes.add}`
              );
            }}
          >
            Generate Code
          </button>
        </div>
        <br />
      </div>
      {/* <div className="Popup-box">
        <div className="Popup-header">
          <p>Option 2</p>
        </div>
        <div className="Popup-content">
          <p>
            You can Generate your one time code and share it to them, so they
            can use it to add you.
          </p>
          <br />
          <button
            className="btn-full btn-primary"
            onClick={() => {
              navigate(
                role === roles.manager
                  ? `/dashboard/managed_influencers/${modes.add}`
                  : `/dashboard/my_managers/${modes.add}`
              );
            }}
          >
            Generate Code
          </button>
        </div>
      </div> */}
    </div>
  );
}

const AddManagerWithCodePopup = ({ influencer_id, setCodePopupState }) => {
  const dispatch = useDispatch();
  const codeSchema = yup.object().shape({
    code: yup.string().required("This is required"),
  });
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(codeSchema) });

  const onSubmit = async (data) => {
    confirmManager(data.code, influencer_id).then((manager) => {
      if (manager === false) setError("code", { message: "Incorrect code!" });
      else {
        dispatch(initManager({ id: "self", manager: manager.id }));
        dispatch(setManager({ [manager.id]: { ...manager } }));
        setCodePopupState(false);
      }
    });
  };
  return (
    <div className="Popup-container">
      <div className="Popup-box">
        <div className="Popup-header">
          <p>Connection Code</p>
          <button
            onClick={() => {
              setCodePopupState(false);
            }}
          >
            <img src={cross_icon_grey} alt="close popup" />
          </button>
        </div>
        <div className="Popup-content">
          <p>
            You can connect with your manager via code that is shared by them
            with you
          </p>
          <div className="Confirmation-code-input-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                className="Connect-code-input"
                type="text"
                placeholder="Paste your code here"
                {...register("code")}
              />
              {errors.code ? <p>{errors.code && errors.code.message}</p> : null}
              <br />
              <button
                type="submit"
                className="Btn Btn-text Btn-text-medium btn-full btn-primary"
              >
                Verify Connection
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddInfluencerWithCodePopup = ({ manager_id, setCodePopupState }) => {
  const dispatch = useDispatch();
  const codeSchema = yup.object().shape({
    code: yup.string().required("This is required"),
  });
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(codeSchema) });

  const onSubmit = async (data) => {
    confirmInfluencer(data.code, manager_id).then((influencer) => {
      if (influencer === false)
        setError("code", { message: "Incorrect code!" });
      else {
        dispatch(initInfluencer({ id: "self", influencer: influencer.id }));
        dispatch(setInfluencers({ [influencer.id]: { ...influencer } }));
        setCodePopupState(false);
      }
    });
  };
  return (
    <>
      <Popup>
        <div className="Popup-header">
          <p>Connection Code</p>
          <button onClick={() => setCodePopupState(false)}>
            <img src={cross_icon_grey} alt="close popup" />
          </button>
        </div>
        <div className="Popup-content">
          <p>
            You can connect with your influencer via code that is shared by them
            with you
          </p>
          <div className="Confirmation-code-input-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                className="Connect-code-input"
                type="text"
                placeholder="Paste your code here"
                {...register("code")}
              />
              {errors.code ? <p>{errors.code && errors.code.message}</p> : null}{" "}
              <br />
              <button
                type="submit"
                className="Btn Btn-text Btn-text-medium btn-full btn-primary"
              >
                Verify Connection
              </button>
            </form>
          </div>
        </div>
      </Popup>
    </>
  );
};

function InvalidRedirectPopup({ auth, userId, navigate, role }) {
  return (
    <Popup>
      <div className="Popup-header">
        <p>Woah {role}..!</p>
      </div>
      <div className="Popup-content">
        <p>It looks like you are trying to access an invalid page!</p>
        <button
          onClick={() => {
            if (auth !== null && userId !== null)
              navigate(`/dashboard/${role}`, { replace: true });
            else navigate("/", { replace: true });
          }}
          className="Btn Btn-text Btn-text-medium btn-full btn-primary"
        >
          Go To Home
        </button>
      </div>
    </Popup>
  );
}

function RedirectPopupProfile({ auth, userId, navigate }) {
  return (
    <Popup>
      <div className="Popup-header">
        <p>Please complete your profile</p>
      </div>
      <div className="Popup-content">
        <p>
          Oops! You can not access this feature till you complete your profile!
        </p>
        <button
          onClick={() => {
            if (auth !== null && userId !== null)
              navigate("/dashboard/profile/new", { replace: true });
            else navigate("/", { replace: true });
          }}
          className="Btn Btn-text Btn-text-medium btn-full btn-primary"
        >
          Complete Profile
        </button>
      </div>
    </Popup>
  );
}

function OtpPopup({
  country_code,
  phone,
  confirmationResult,
  setSignInState,
  retryOTP,
  setCodePopupState,
}) {
  const [submit, setSubmit] = useState(false);
  const codeSchema = yup.object().shape({
    code: yup.string().required("This is required"),
  });
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(codeSchema) });

  const onSubmit = async (data) => {
    setSubmit(true);
    confirmationResult
      .confirm(data.code)
      .then((result) => {
        console.log("FROM OTP POPUP", result);
        setSignInState({ ...result, country_code: country_code });
      })
      .catch((error) => {
        setSubmit(false);
        console.log("Error from OTP Popup", error.message);
        setError("code", { message: "Incorrect OTP" });
      });
  };

  const [otpCounter, setOtpCounter] = useState(15);
  useEffect(() => {
    if (otpCounter !== 0 && otpCounter !== false)
      setTimeout(() => {
        setOtpCounter(otpCounter - 1);
      }, 1000);
  }, [otpCounter]);

  return (
    <div className="Popup-content">
      <div className="border-bottom-grey margin-bottom-1"></div>
      <p>
        We have sent your OTP to{" "}
        <span className="span-text-bold accent-text">{phone}</span>.
      </p>
      {otpCounter === false ? null : otpCounter !== 0 ? (
        <p>Resend in {otpCounter} seconds</p>
      ) : (
        <p
          onClick={() => {
            setOtpCounter(false);
            retryOTP(phone);
            setSubmit(false);
          }}
          className="text-link"
        >
          Resend OTP
        </p>
      )}
      <div className="Confirmation-code-input-container">
        <form style={{ textAlign: "center" }} onSubmit={handleSubmit(onSubmit)}>
          <input
            className="Connect-code-input"
            type="text"
            inputMode="numeric"
            placeholder="Enter OTP"
            {...register("code")}
          />
          {errors.code ? <p>{errors.code && errors.code.message}</p> : null}{" "}
          <br />
          {submit ? (
            <LoaderDot variant="inline" />
          ) : (
            <button
              id="sign-in-submit"
              type="submit"
              className="Btn Btn-text Btn-text-medium Btn-full Btn-primary"
            >
              Submit
            </button>
          )}
          <button
            onClick={() => setCodePopupState(false)}
            type="button"
            className="Btn Btn-text Btn-text-medium Btn-full Btn-secondary margin-top-1"
          >
            Go back
          </button>
        </form>
      </div>
    </div>
  );
}

function RenderCodePopup({ popup_role, code }) {
  const navigate = useNavigate();
  return (
    <Popup>
      <div className="Popup-header">
        <p>Connection Code</p>
        <button
          onClick={() =>
            navigate(
              `${
                popup_role === roles.manager
                  ? "/dashboard/influencer"
                  : "/dashboard/manager"
              }`
            )
          }
        >
          <img src={cross_icon_grey} alt="close popup" />
        </button>
      </div>
      <div className="Popup-content">
        <p>
          <span style={{ fontWeight: 600 }}>Share the code </span>with your{" "}
          {popup_role}, once they sign in and= enter the code you will be
          connected with them
        </p>
        <div className="Confirmation-code-container">
          <p className="Confirmation-code">{code}</p>
          <button
            onClick={() => navigator.clipboard.writeText(code)}
            className="share-button"
          >
            Copy Code
          </button>
        </div>

        <div className="Popup-note">
          <p>
            <span style={{ color: "var(--color-accent)", fontWeight: "500" }}>
              Note:
            </span>{" "}
            your {popup_role} needs to sign in with same email/mobile entered by
            you
          </p>
        </div>
      </div>
    </Popup>
  );
}

export function SupportPopup({ setPopupState }) {
  return (
    <Popup setPopup={setPopupState}>
      <div className="Popup-header">
        <p> Contact Support</p>{" "}
        {/*  <button onClick={() => setPopupState(false)}>
          <img src={cross_icon_grey} alt="close popup" />
        </button> */}
      </div>
      <div className="Popup-content">
        <p>
          Hi! Have any doubts or queries? Feel free to connect with us at
          <br />
          Email: <span className="text-bold">support@socialpi.ai</span>
          <br />
          Whatsapp: <span className="text-bold">+918178369930</span>
        </p>
        <div className="text-align-center margin-top-small">
          <button>
            <a
              href="https://wa.me/918178369930"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: "14rem", objectFit: "contain" }}
                src={WhatsAppButtonGreenLarge}
                alt="Chat on whatsapp"
              />
            </a>
          </button>
          <button
            style={{ width: "14rem", height: "3rem" }}
            className="btn-tertiary fs-1-1"
          >
            <a href="mailto:support@socialpi.ai">Send an E-mail</a>
          </button>
        </div>
      </div>
    </Popup>
  );
}

export {
  CodeCreatePopup,
  AddManagerWithCodePopup,
  AddInfluencerWithCodePopup,
  AddOptionsPopup,
  UploadImagePopup,
  InvalidRedirectPopup,
  RedirectPopupProfile,
  RenderCodePopup,
  OtpPopup,
};
export default Popup;
