export const modes = {
  edit: "edit",
  create: "create",
  view: "view",
  add: "add",
  select: "select",
};

export const platforms = {
  youtube: "youtube",
  instagram: "instagram",
  twitter: "twitter",
  facebook: "facebook",
  telegram: "telegram",
  custom: "custom",
  /*   discord: "discord", */
};

export const platform_name_list = Object.keys(platforms);

export const root_routes = {
  login: "login",
  register: "register",
  manager: "manager",
  influencer: "influencer",
  giveaways: "giveaways",
};

export const login_routes = {
  influencer_selection: "influencer_selection",
  create_profile: "profile/create",
  add_influencer: "managed_influencers/create",
  add_alias: "aliases/add",
  add_socials: "socials",
  platform_details: "platform_details/add",
};

export const storage_routes = {
  profile_uploads: "profile_uploads",
  managers: "/managers",
  influencers: "/influencers",
  display_picture: "/display_picture",
  aliases: "/aliases",
  alias_photo: "/alias_photo",
};

export const header_variants = {
  large: "large",
  slim: "slim",
  medium: "medium",
};

export const roles = { manager: "manager", influencer: "influencer" };

export const giveaway_status = {
  drafts: "drafts",
  running: "running",
  scheduled: "scheduled",
  completed: "completed",
  ended: "ended",
  paused: "paused",
};

export const reward_types = {
  coupon: "coupon",
  product: "product",
  cash: "cash",
  voucher: "voucher",
  tickets: "tickets",
  custom: "custom",
};

export const reward_statuses = {
  pending_generation: "pending winner generation",
  pending_announcement: "pending announcement",
  pending_distribution: "pending distribution",
  distributed: "distributed",
};

export const defaults = {
  giveaways: {
    tasks: {
      twitter_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FTwitter.png?alt=media&token=12e09f97-80a8-427e-8cf9-62d99fae338b",
      instagram_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FInstagram.png?alt=media&token=d57e3d4e-88b3-4382-a257-2acb84e2337d",
      youtube_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FYoutube.png?alt=media&token=7ada93af-4867-4bd0-9d99-e0993cf6959c",
      telegram_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FTelegram.png?alt=media&token=ea33b15f-d034-4de0-bb18-4a621fd1926c",
      facebook_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FFacebook.png?alt=media&token=a54c7b92-0121-484a-9a1b-8efd5860772f",
      discord_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FDiscord.png?alt=media&token=0f5509d0-9926-4053-ac58-f1829664641a",
      custom_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Fpreset_preview.svg?alt=media&token=42c56ca1-b8a6-47ff-b563-32254827b9f7",
    },
  },
};

export const env = process.env.NODE_ENV || "development";
export const callbackurls = {
  facebook: "",
  instagram: "",
  twitter:
    env === "development"
      ? process.env.REACT_APP_TEST_TWT_AUTH_CALLBACK_URL
      : process.env.REACT_APP_TWT_AUTH_CALLBACK_URL,
  youtube:
    env === "development"
      ? process.env.REACT_APP_TEST_YT_AUTH_CALLBACK_URL
      : process.env.REACT_APP_YT_AUTH_CALLBACK_URL,
};
export const api_url =
  env === "development"
    ? process.env.REACT_APP_TEST_SERVER_URL
    : process.env.REACT_APP_SERVER_URL;

export const api_endpoints = {
  socials: {
    twitter: "/api/social/twitter",
    facebook: "/api/social/facebook",
    instagram: "/api/social/instagram",
    youtube: "/api/social/youtube",
  },
  auth: {
    twitter: "/api/auth/twitter",
    facebook: "/api/auth/facebook",
    instagram: "/api/auth/instagram",
    youtube: "/api/auth/youtube",
  },
  khiladi: {
    base: "/api/brands/khiladiadda",
    get_khiladi_status: "/getKhiladiStatus",
  },
  msg91: {
    base: "/api/msg91",
    sendOTP: "/sendOTP",
    verifyOTP: "/verifyOTP",
    resendOTP: "/resendOTP",
  },
};

export const month_array = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
};

export const month_name = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const res_codes = {
  success: 200,
  error: 400,
  update_success: 201,
  update_failed: 401,
  delete_success: 202,
  delete_failed: 402,
  write_success: 203,
  write_failed: 403,
  read_success: 205,
  read_failed: 405,
};

export const ob_sources = { register: "register", sign_in: "sign_in" };

export const initial_giveaway_state = {
  drafts: {},
  running: {},
  paused: {},
  scheduled: {},
  ended: {},
  completed: {},
};

export const campaign_ids = {
  sponsor_campaign_id_june_2023: "giveaway_influencer_acquisition_06_2023",
};

export const reward_amounts = [100, 250, 500, 1000, 2000, 3000, 5000];

export const reward_denominations = {
  [campaign_ids.sponsor_campaign_id_june_2023]: {
    100: [100],
    250: [250],
    500: [500],
    1000: [500, 1000],
    2000: [500, 1000, 2000],
    2500: [500, 1250, 2500],
    3000: [500, 1000, 1500, 3000],
    5000: [500, 1000, 2500, 5000],
  },
};
