import { useEffect, useState } from "react";
import { UploadImagePopup } from "./Popups";
import avatar_placeholder from "../../assets/img/svg/avatar_placeholder.svg";
import upload_icon_white from "../../assets/img/svg/upload_icon_white.svg";

function DisplayPictureUpload({
  dbUpdate,
  folder_path,
  styles,
  photoUrl = "",
}) {
  const [displayPicture, setDisplayPicture] = useState("");
  const [photoPop, setPhotoPop] = useState(false);
  console.log(folder_path);
  useEffect(() => {
    if (displayPicture !== "") dbUpdate(displayPicture);
    if (photoUrl !== "") setDisplayPicture(photoUrl);
  }, [displayPicture, dbUpdate, photoUrl]);
  return (
    <div className={styles.parent}>
      <div className={styles.avatar}>
        <img
          className={styles.image ?? ""}
          src={
            displayPicture === "" && photoUrl === ""
              ? avatar_placeholder
              : displayPicture
          }
          alt="avatar"
        />
      </div>
      <p onClick={() => setPhotoPop(true)} className="text-link text-bold">
        Upload Photo
      </p>
      {/* <div className="btn-icon-absolute">
        <button
          onClick={() => setPhotoPop(true)}
          className="btn-primary btn-icon-round Avatar-upload-btn"
        >
          <img src={upload_icon_white} alt="upload button" />
        </button>
      </div> */}
      {photoPop ? (
        <UploadImagePopup
          setParentPopupState={setPhotoPop}
          setImage={setDisplayPicture}
          variant="avatar"
          size={1024}
          dimension={"100px x 100px"}
          folder_path={folder_path}
        />
      ) : null}
    </div>
  );
}

export default DisplayPictureUpload;
