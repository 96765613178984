import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";

/*aliasDocref: {
  id: "",
  manager:"",
  influencer:"",
  platforms: {youtube:[{}], facebook:[{}], instagram: [{}], twitter:[{}]},
  giveaways: {},
  memberships: {},
  marketplace_id: "",
  donations: {},
  permissions:{
    managerDocRef:{
      data_point: {read: bool, write: bool},
    },
},*/

const initialState = {};

export const aliasSlice = createSlice({
  name: "aliases",
  initialState,
  reducers: {
    resetAliases: () => {
      return {};
    },
    setAliases: (state, action) => {
      return { ...state, ...action.payload };
    },

    initAlias: (state, action) => {
      if (state[action.payload.influencer] === undefined)
        state[action.payload.influencer] = {
          [action.payload.data.id]: {
            ...action.payload.data,
          },
        };
      else
        state[action.payload.influencer][action.payload.data.id] = {
          ...action.payload.data,
        };
    },

    editAliasDetails: (state, action) => {
      const { influencer, alias } = action.payload;
      state[influencer][alias] = {
        ...state[influencer][alias],
        ...action.payload.data,
      };
    },
    addAliasPhoto: (state, action) => {
      const { influencer, alias, photo } = action.payload;
      state[influencer][alias].photo = photo;
    },

    deleteAlias: (state, action) => {
      const { influencer, alias } = action.payload;
      let new_state = { ...state[influencer] };
      delete new_state[alias];
      /*     Object.keys(state[influencer]).map((aliasId) => {
        if (aliasId !== alias)
          new_state = {
            ...new_state,
            [`${aliasId}`]: state[influencer[aliasId]],
          };
      }); */
      state[influencer] = { ...new_state };
    },

    addAliasPlatform: (state, action) => {
      const { influencer, alias, platform } = { ...action.payload };
      state[influencer][alias].platforms[platform.type][
        platform.data.username
      ] = {
        ...platform.data,
      };
    },

    deleteAliasPlatform: (state, action) => {
      const { influencer, alias, platform } = { ...action.payload };
      console.log(action.payload);
      let platforms = state[influencer][alias].platforms[platform.type];
      delete platforms[platform.handle];
      state[influencer][alias].platforms[platform.type] = { ...platforms };
    },
  },
});

export const {
  resetAliases,
  setAliases,
  initAlias,
  addAliasPlatform,
  editAliasDetails,
  deleteAliasPlatform,
  deleteAlias,
  addAliasPhoto,
} = aliasSlice.actions;
export default aliasSlice.reducer;
