import React from "react";
import { NavLink } from "react-router-dom";
import cross_icon from "../../assets/img/svg/cross_icon_green.svg";
import { header_variants } from "../../meta";

function GiveawayHeader({
  variant,
  current_step,
  children,
  giveaway_id,
  toggleModal,
  disabled,
}) {
  const steps = [1, 2, 3, 4];
  const InactiveStep = () => {
    return (
      <svg width="20" height="20">
        <circle
          cx="10"
          cy="10"
          r="8"
          stroke="none"
          strokeWidth="2"
          fill="#D4E3E3"
        />
      </svg>
    );
  };
  const ActiveStep = () => {
    return (
      <div className="Active-step">
        <svg width="20" height="20">
          <circle
            cx="10"
            cy="10"
            r="8"
            stroke="#3B8D9E"
            strokeWidth="4"
            fill="#D4E3E3"
          />
        </svg>
      </div>
    );
  };

  return (
    <div className={`Giveaway-create-header`}>
      <div className="Giveaway-create-header-nav flex-row flex-align-center">
        {disabled ? null : (
          <>
            {" "}
            <NavLink
              className={({ isActive }) => {
                if (isActive)
                  return "Giveaway-create-header-nav-option-selected";
                else return "";
              }}
              to={`../create/${giveaway_id}/title_and_description`}
            >
              Details
            </NavLink>
            <NavLink
              className={({ isActive }) => {
                if (isActive)
                  return "Giveaway-create-header-nav-option-selected";
                else return "";
              }}
              to={`../create/${giveaway_id}/rewards`}
            >
              Rewards
            </NavLink>
            <NavLink
              className={({ isActive }) => {
                if (isActive)
                  return "Giveaway-create-header-nav-option-selected";
                else return "";
              }}
              to={`../create/${giveaway_id}/tasks`}
            >
              Tasks
            </NavLink>{" "}
          </>
        )}
      </div>

      {children}
    </div>
  );
}

export default GiveawayHeader;
