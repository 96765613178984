import { useRef } from "react";
import { useEffect, useState } from "react";

export function useWindowHeight() {
  const [vh, setVH] = useState(window.innerHeight * 0.01);
  const handleResize = () => {
    setVH(window.innerHeight * 0.01);
  };
  window.addEventListener("resize", handleResize);
  useEffect(() => {
    return () => window.removeEventListener("resize", handleResize);
  });
  return vh;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useResponsive() {
  const [vw, setvw] = useState(window.innerWidth);
  const handleWidthResize = () => {
    setvw(window.innerWidth);
  };
  window.addEventListener("resize", handleWidthResize);
  useEffect(() => {
    return window.removeEventListener("resize", handleWidthResize);
  });
  return vw <= 600 ? true : false;
}
