import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const sponsoredCampaignSlice = createSlice({
  name: "sponsoredCampaigns",
  initialState: initialState,
  reducers: {
    resetCampaigns: () => {
      return {};
    },

    setCampaigns: (state, action) => {
      return { ...state, ...action.payload };
    },

    addCampaign: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },

    claimSponsorship: (state, action) => {
      const {
        campaign_id,
        giveaway_id,
        giveaway_reward_id,
        sponsored_reward_id,
      } = action.payload;

      const updated_state = {
        sponsorship_claimed: true,
        sponsored_rewards: {
          [sponsored_reward_id]: {
            ...state[campaign_id].sponsored_rewards[sponsored_reward_id],
            reward_claimed: true,
            claimed_in_giveaway: giveaway_id,
            claimed_in_reward: giveaway_reward_id,
          },
        },
      };

      state[campaign_id] = { ...state[campaign_id], ...updated_state };
    },

    unclaimSponsorship: (state, action) => {
      const { campaign_id, sponsored_reward_id } = action.payload;
      const updated_state = {
        sponsorship_claimed: false,
        sponsored_rewards: {
          [sponsored_reward_id]: {
            ...state[campaign_id].sponsored_rewards[sponsored_reward_id],
            reward_claimed: false,
            claimed_in_giveaway: null,
            claimed_in_reward: null,
          },
        },
      };

      state[campaign_id] = { ...state[campaign_id], ...updated_state };
    },
  },
});

export const {
  resetCampaigns,
  setCampaigns,
  addCampaign,
  unclaimSponsorship,
  claimSponsorship,
} = sponsoredCampaignSlice.actions;
export default sponsoredCampaignSlice.reducer;
