import { createSlice } from "@reduxjs/toolkit";
//import { loadState } from "../localStorage";
/****************************
 # USER SLICE DATA STRUCTURE
 # Data From Firebase:
 * auth: {uid: string, 
    provider: string (google/facebook/mobile), 
    display_name: string, 
    email: string,
    photo_url: string,
    access_token: string, 
    refresh_token: string,},
 * roles: {manager: bool, influencer: bool, agency: bool},
 * default_role: string (manager/influencer/agency),
 * current_role: string (manager/influencer/agency),
 * 
 # Data unique to redux store:
 * selected_role: string (manager/influencer/agency),
 * onboard_state : {
    initial_profile: false,
    billing_details: false,
    alias_details: false,
 * }
****************************/

/*
Dummy Data:  {
  auth: {
    uid: "dummy123",
    provider: "dev-test",
    roles: { manager: true, influencer: false },
  },
  profile: {
    first_name: "",
    last_name: ""
    email: "testdata@abc.com",
    mobile:"",
    gender:"",
    age_range:"",
    photo_url:
      "https://www.pinclipart.com/picdir/middle/148-1486972_mystery-man-avatar-circle-clipart.png",
  },
  default_role: "manager",
  current_role: "manager",
  onboard_state: {
    initial_profile: false,
    billing_details: false,
    alias_details: false,
  },
};
*/

/* const initialState = {
  auth: null,
  profile: null,
  default_role: null,
  current_role: null,
  onboard_state: {
    initial_profile: false,
    billing_details: false,
    alias_details: false,
  },
}; */

const initialState = {
  auth: null,
  profile: { email: "", mobile: "" },
  current_role: null,
  connection_data: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.auth = action.payload;
    },
    logout: () => {
      return { auth: null, profile: { ...initialState.profile } };
    },
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    setOnboardState: (state, action) => {
      state.onboard_state[action.payload.form] = true;
    },
    setUserRole: (state, action) => {
      state.current_role = action.payload;
      if (state.default_role === null) state.default_role = action.payload;
    },
    setRewardCriteria: (state, action) => {
      const { reward, criteria, giveawayId } = action.payload;
      state.current_criteria = {
        reward: reward,
        criteria: criteria,
        giveaway: giveawayId,
      };
    },
    updateUserSlice: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const {
  login,
  logout,
  setOnboardState,
  setUserRole,
  setUserProfile,
  setUser,
  setRewardCriteria,
  updateUserSlice,
} = userSlice.actions;
export default userSlice.reducer;
