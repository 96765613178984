import { initial_giveaway_state } from "../meta";
import { resetAliases, setAliases } from "./aliasSlice";
import { setGiveaways } from "./giveawaySlice";
import { resetInfluencers, setInfluencers } from "./influencerSlice";
import { setManager } from "./managerSlice";
import { resetCampaigns } from "./sponsoredCampaignSlice";

export function globalLogout({ dispatch }) {
  dispatch(resetAliases());
  dispatch(setManager({}));
  dispatch(resetInfluencers());
  dispatch(resetCampaigns());
  dispatch(setGiveaways(initial_giveaway_state));
}
