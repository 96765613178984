import React from "react";

//import avatar from "../../assets/img/example_vectors/user_avatar.png";
//import chatbubble from "../../assets/img/svg/chatbubble.svg";
import fb from "../../assets/img/svg/sm_icons/fb.svg";
import yt from "../../assets/img/svg/sm_icons/yt.svg";
import ins from "../../assets/img/svg/sm_icons/ins.svg";
import twt from "../../assets/img/svg/sm_icons/twt.svg";
import { useNavigate } from "react-router-dom";
import AvatarCircle from "../common/AvatarCircle";

//import blur from "../../assets/img/blur707070.jpg";

function InfluencerCard({ data, stats, status, type }) {
  const navigate = useNavigate();

  const navigateToAddSocials = () => {};

  const total_count =
    stats?.youtube + stats?.instagram + stats?.facebook + stats?.twitter;
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (type === "alias")
          navigate(`/dashboard/self/${data.alias_id}/socials`);
        else navigate(`/dashboard/${data.id}/aliases/add`);
      }}
      className="Inf-profile-card"
    >
      <div className="Inf-avatar">
        {type === "alias" && data.photo.length !== 0 ? (
          <img
            style={{
              backgroundColor: "rgba(0,0,0,0)",
            }}
            className="Card-avatar box-align-center"
            src={data.photo}
            alt="Alias"
          />
        ) : (
          <AvatarCircle
            photo={data.photo}
            first_name={data.first_name}
            last_name={data.last_name}
            styles={"Card-avatar"}
          />
        )}
      </div>
      <div className="Inf-name">
        <span className="span-text-bold">
          <p>
            {data.first_name} {data.last_name}
          </p>
        </span>
        <p style={{ fontSize: "0.7rem" }}>{data.email}</p>
      </div>
      {/* <div className="Inf-chat">
        <img src={chatbubble} alt="chat bubble" />
      </div> */}
      {type === "alias" ? null : (
        <div
          className={`Inf-stat Inf-state ${
            status === "connected" && type === "manager"
              ? "Inf-state-connected"
              : "Inf-state-disconnected"
          }`}
        >
          <p>
            {/* <span className="span-text-bold">{stats.giveaways}</span> running
          giveaways */}
            {status}
          </p>
        </div>
      )}

      {total_count === 0 ? (
        <div
          className={`Platform-overview-button ${
            status === "not connected" || type === "alias"
              ? "btn-state-disconnected"
              : "btn-state-connected"
          }`}
        >
          <button
            onClick={() => {
              if (status === "not connected") {
                navigator.clipboard.writeText(data.code);
              } else if (type === "alias")
                navigate(`./self/${data.alias_id}/socials`);
              else if (type === "influencer" && status === "connected")
                navigate(`./${data.alias_id}/aliases/add`);
            }}
          >
            {status === "not connected"
              ? "Copy Connection Code"
              : type === "manager"
              ? null
              : "Add Usernames"}{" "}
          </button>{" "}
        </div>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            /*  if (type === "alias") navigate(`./self/${data.first_name}/socials`);
            else navigate(`./${data.id}/aliases/add`); */
          }}
          className="Platform-overview-container"
        >
          <div className="Platform-count-indicator">
            <div className="Platform-icon">
              <img src={yt} alt="youtube" />
            </div>
            <span className="Platform-count-text">{stats.youtube}</span>
          </div>
          <div className="Platform-count-indicator">
            <div className="Platform-icon">
              <img src={ins} alt="instagram" />
            </div>
            <span className="Platform-count-text">{stats.instagram}</span>
          </div>
          <div className="Platform-count-indicator">
            <div className="Platform-icon">
              <img src={fb} alt="facebook" />
            </div>
            <span className="Platform-count-text">{stats.facebook}</span>
          </div>
          <div className="Platform-count-indicator">
            <div className="Platform-icon">
              <img src={twt} alt="twitter" />
            </div>
            <span className="Platform-count-text">{stats.twitter}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default InfluencerCard;
