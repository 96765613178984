import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
/* const initialState = {
  auth: { uid: null, platform: null },
  profile: {
    first_name: null,
    last_name: null,
    id: null,
    email: null,
    mobile: null,
    age: null,
    is_manager: false,
  },
  influencers: [
  ],
  billing_details: {
    name: null,
    mobile: null,
    email: null,
    billing_address: {
      line1: null,
      line2: null,
      country: null,
      state: null,
      city: null,
      pin: null,
    },
    payment: {
      mode: null,
      details: {},
    },
  },
}; */
const initialState = loadState().managers;

export const managerSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    initAuth: (state, action) => {
      state.auth = action.payload;
    },
    setManager: (state, action) => {
      return { ...state, ...action.payload };
    },
    initProfile: (state, action) => {
      state.profile = action.payload;
    },
    initInfluencer: (state, action) => {
      state[action.payload.id].influencers = [
        ...state[action.payload.id].influencers,
        action.payload.influencer,
      ];
    },
    addUnconfirmedInfluencer: (state, action) => {
      const { id, data } = action.payload;
      state[id].unconfirmed_influencers = {
        ...state[id].unconfirmed_influencers,
        ...data,
      };
    },
    initBillingDetails: (state, action) => {
      state.billing_details = action.payload;
    },
  },
});

export const {
  initAuth,
  initProfile,
  initInfluencer,
  addUnconfirmedInfluencer,
  initBillingDetails,
  setManager,
} = managerSlice.actions;

export default managerSlice.reducer;
