export function SH1({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h1 style={{ ...styles }} className={`${classes} SH1`}>
        {children}
      </h1>
    )
  );
}

export function SH2({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h2 style={{ ...styles }} className={`${classes} SH2`}>
        {children}
      </h2>
    )
  );
}

export function SH3({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h3 style={{ ...styles }} className={`${classes} SH3`}>
        {children}
      </h3>
    )
  );
}
