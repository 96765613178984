import { platforms } from "../meta";
import { logging } from "./consoleFunctions";

function getAllGiveawayTasks(tasks) {
  var task_platforms = [...Object.values(tasks)]; //-> [{account: {}, post_activity:{}...}, {}, {}, {...}]
  //Object.values(task_platforms[i]) -> [{...account, ...post_activity...}, {twt}, {yt}, {..}]
  var activity_tasks = [];
  var all_tasks = [];

  for (var i = 0; i < task_platforms.length; i++)
    activity_tasks = [...activity_tasks, ...Object.values(task_platforms[i])];
  //[{...account, ...post_activity...}, {twt}, {yt}, {..}]
  for (i = 0; i < activity_tasks.length; i++)
    all_tasks = [...all_tasks, ...Object.values(activity_tasks[i])];
  return all_tasks;
}

function getDuratonInDays(start_date, end_date) {
  const start = new Date(start_date);
  const end = new Date(end_date);

  return Math.floor((end - start + 1) / (1000 * 60 * 60 * 24));
}

function getGiveawayTimelineProgress(start, end) {
  const today = new Date();
  const current_day = today.getDate() - new Date(start).getDate();

  const progress = current_day / getDuratonInDays(start, end);
  console.log(progress);
  return progress;
}

function getGiveawayRemainingDays(start_date, end_date) {
  const today = new Date();
  const start = new Date(start_date);
  const end = new Date(end_date);
  const current_day = Math.floor((today - start + 1) / (1000 * 60 * 60 * 24));
  const total = Math.floor((end - start + 1) / (1000 * 60 * 60 * 24));
  const remaining = total - current_day;

  return remaining;
}

function randomizeCandidates(limit, all_winners) {
  console.log(all_winners);
  let winners = [];
  for (let i = 0; i < limit; i++) {
    const random = Math.floor(Math.random() * all_winners.length);
    winners = [...winners, ...all_winners.splice(random, 1)];
  }
  return winners;
}

function platformWiseTaskCount(tasks) {
  let count = {
    youtube: 0,
    twitter: 0,
    instagram: 0,
    custom: 0,
    telegram: 0,
    facebook: 0,
  };
  tasks.forEach((task) => {
    count[task.platform] += 1;
  });

  return count;
}

function getTotalTicketCount(tasks) {
  let ticket_count = 0;
  tasks.forEach((task) => {
    ticket_count += parseInt(task.tickets);
  });
  return ticket_count;
}

function getViewPortWidth() {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
}

function openInNewTab(url) {
  window.open(url, "_blank", "noopener,noreferrer");
}

export function getFullDate(date_in_ms) {
  /*  logging({
    from_file: "commonFunctions.js",
    from_function: "getFullDate",
    data: date_in_ms,
    message: "Date passed in millisecond",
  }); */
  const new_date = new Date(date_in_ms);
  const full_date =
    new_date.getDate() +
    "/" +
    (new_date.getMonth() + 1) +
    "/" +
    new_date.getFullYear();
  return full_date;
}

const milli_to_days = 1000 * 60 * 60 * 24;

export const id_from_url = (url) => {
  return url
    .split("?")[0]
    .split("/")
    .filter((item) => item.length !== 0)
    .reverse()[0];
};

export const isPlatformLinkValid = (url, platform) => {
  const contains_id =
    url
      .replace("https://", "")
      .split("?")[0]
      .split("/")
      .filter((segment) => segment.length !== 0).length > 1
      ? true
      : false;

  const is_platform_link =
    platform === platforms.telegram
      ? url.includes("tlgrm") || url.includes("t.me")
      : platform === platforms.facebook
      ? url.includes("fb") || url.includes(platform)
      : platform === platforms.youtube
      ? url.includes("youtu.be") || url.includes(platform)
      : url.includes(platform);

  if (contains_id) return is_platform_link;
  return false;
};

export const getNormalizedUrl = (url) => {
  return url.split("?")[0];
};

export {
  getAllGiveawayTasks,
  getGiveawayTimelineProgress,
  getDuratonInDays,
  randomizeCandidates,
  platformWiseTaskCount,
  getTotalTicketCount,
  getGiveawayRemainingDays,
  getViewPortWidth,
  openInNewTab,
  milli_to_days,
};
