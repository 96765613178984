import { env } from "../meta";

export function logDataToConsole(
  source_file,
  source_function,
  operation,
  data
) {
  console.log(
    `DATA LOG FROM COMPONENT: ${source_file}.js \nFunction: ${source_function} \nOPERATION: ${operation} \nDATA:`,
    data
  );
}

export function logging({ message = "", data, from_file, from_function }) {
  let intro_css = "color: #50abf1; font-size: 1rem;";
  let source_css = "color: #9dffdc; font-size: 0.8rem";
  let data_css = "color: #ff6b6b; font-size:0.9rem";
  if (env !== "development") return null;
  console.log(
    "%cConsole Log \n%cFrom File: %s \nIn Function: %s \n%s \n%c--Data Start--",
    intro_css,
    source_css,
    from_file,
    from_function,
    message,
    data_css
  );
  console.log(data);
  console.log("%c--Data End--", data_css);
}

export const fb_stages = {
  read: "read",
  write: "write",
  return_after_write: "return_after_write",
  return_after_read: "return_after_read",
  write_success: "write_success",
  read_success: "read_success",
};

export function loggingFB({ stage = "", data, from_file, from_function }) {
  if (env !== "development") return null;
  const message = () => {
    switch (stage) {
      case "read":
        return "Data read from firebase";
      case "write":
        return "Data written to firebase";
      case "return_after_write":
        break;
      case "return_after_read":
        break;
      default:
        break;
    }
  };
  let intro_css = "color: #50abf1; font-size: 1rem;";
  let source_css = "color: #9dffdc; font-size: 0.8rem";
  let data_css = "color: #ff6b6b; font-size:0.9rem";
  if (env !== "development") return null;
  console.log(
    "%cConsole Log \n%cFrom File: %s \nIn Function: %s \n%s \n%c--Data Start--",
    intro_css,
    source_css,
    from_file,
    from_function,
    `message`,
    data_css
  );
  console.log(data);
  console.log("%c--Data End--", data_css);
}
