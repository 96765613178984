import { initializeApp, getApps, getApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  arrayRemove,
  arrayUnion,
  serverTimestamp,
  /* addDoc,*/
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
  signInWithPhoneNumber,
  browserLocalPersistence,
} from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { giveaway_status, reward_types, roles } from "../meta";
import { randomizeCandidates } from "./commonFunctions";

//import _ from "lodash";
import { isEqual, intersection } from "lodash";
import { logging } from "./consoleFunctions";
import { setTimestamps } from "./schemas/common";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = !getApps.length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const uploadImageToFirebase = async (file, folder, setProgress) => {
  const metadata = {
    contentType: "image/jpeg",
  };
  const imageRef = ref(storage, folder);
  const uploadTask = uploadBytesResumable(imageRef, file, metadata);
  return new Promise((resolve) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setProgress(progress.toFixed(0));

        switch (snapshot.state) {
          case "paused":
            //console.log("Upload is paused");
            break;
          case "running":
            //console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          return resolve(downloadURL);
        });
      }
    );
  });
};

/* const authWithPeristsance = async () => {
  setPersistence(auth, browserSessionPersistence)
    .then(() => {
      return signInWithPopup(auth, googleProvider);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
    });
}; */

export const googleProvider = new GoogleAuthProvider();

/*##########################################################
################## DATA FETCH ON REFRESH ###################
###########################################################*/

const fetchData = (id) => {
  var managers = null;
  var influencers = null;
  var unconfirmedInfluencers = null;
  var unconfirmedManagers = null;
  var giveaways = null;
  var aliases = {};
  return new Promise(async (resolve) => {
    const user = await getDoc(doc(db, "users", id));
    if (!user.exists()) {
      return resolve({ user: false });
    }

    const { default_role } = user.data();

    if (default_role !== null) {
      giveaways = await getGiveaways(id, default_role);

      influencers =
        default_role === roles.influencer
          ? await getSelfInfluencer(id)
          : await getInfluencers(id);
      managers =
        default_role === roles.manager
          ? await getSelfManager(id)
          : await getManagers(id);

      unconfirmedInfluencers = await getUnconfirmedInfluencers([
        ...Object.keys(managers.self?.unconfirmed_influencers ?? {}),
      ]);

      unconfirmedManagers = await getUnconfirmedManagers([
        ...Object.keys(influencers.self?.unconfirmed_managers ?? {}),
      ]);

      var aliasSnapshot;
      var ids_processed = 0;
      const ids_pending =
        default_role === roles.influencer
          ? 1
          : managers.self?.influencers?.length ?? 0;

      if (ids_pending === 0) {
        return resolve({
          user: { ...user.data(), current_role: default_role },
          managers: { ...managers, ...unconfirmedManagers },
          influencers: { ...influencers, ...unconfirmedInfluencers },
          giveaways: { ...giveaways },
          aliases: aliases,
        });
      }
      if (default_role === roles.influencer) {
        aliasSnapshot = await getAliasDetails(id, default_role, default_role);
        aliases = { ...aliases, ...aliasSnapshot };
        ids_processed += 1;
        if (
          managers !== null &&
          influencers !== null &&
          unconfirmedInfluencers !== null &&
          unconfirmedManagers !== null &&
          giveaways !== null &&
          aliases !== null &&
          ids_processed === ids_pending
        )
          return resolve({
            user: { ...user.data(), current_role: default_role },
            managers: { ...managers, ...unconfirmedManagers },
            influencers: { ...influencers, ...unconfirmedInfluencers },
            giveaways: { ...giveaways },
            aliases: aliases,
          });
      }

      if (default_role === roles.manager) {
        managers.self.influencers.forEach(async (influencer) => {
          aliasSnapshot = await getAliasDetails(
            influencer,
            "influencer",
            "manager"
          );
          logging({
            from_file: "fb.js",
            from_function: "fetchData",
            data: aliasSnapshot,
            message: "Alias Snapshot",
          });
          aliases = { ...aliases, ...aliasSnapshot };
          ids_processed += 1;
          logging({
            from_file: "fb.js",
            from_function: "fetchData",
            message: "Influencer IDs processed",
            data: ids_processed,
          });
          if (
            managers !== null &&
            influencers !== null &&
            unconfirmedInfluencers !== null &&
            unconfirmedManagers !== null &&
            giveaways !== null &&
            aliases !== null &&
            ids_processed === ids_pending
          )
            return resolve({
              user: { ...user.data(), current_role: default_role },
              managers: { ...managers, ...unconfirmedManagers },
              influencers: { ...influencers, ...unconfirmedInfluencers },
              giveaways: { ...giveaways },
              aliases: aliases,
            });
        });
      }
    } else if (default_role === null) {
      return resolve({
        user: { ...user.data(), current_role: default_role },
        managers: {},
        influencers: {},
        giveaways: {},
        aliases: {},
      });
    }
  });
};

/*##########################################################
######################### REGISTRATION #####################
###########################################################*/
const createUser = async (auth, user) => {
  const timestamp = Date.now();
  try {
    const userRef = doc(collection(db, "users"));
    const doc_data = {
      timestamps: {
        created_on: timestamp,
        deleted_on: null,
        last_active: null,
        last_updated: null,
        blocked_on: null,
      },
      id: userRef.id,
      auth: { ...auth },
      ...user,
    };
    await setDoc(doc(db, "users", userRef.id), {
      ...doc_data,
    });
    return new Promise((resolve) => resolve({ ...doc_data }));
  } catch (err) {
    console.error("In fb.js, function: createUser during setDoc", err.message);
  }
};

const findUserByAuth = async (provider, id) => {
  //console.log("In findUserByAuth");
  const findUserByAuthQuery = new query(
    collection(db, "users"),
    where(`auth.${provider}.id`, "==", id)
  );
  const userSnap = await getDocs(findUserByAuthQuery);
  if (userSnap.docs.length === 0) return false;
  else
    return {
      ...userSnap.docs[0].data(),
      current_role: userSnap.docs[0].data().default_role,
    };
};

const signUpWithPhone = async (phoneNumber, setConfirmation, verifier) => {
  signInWithPhoneNumber(auth, phoneNumber, verifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code)
      //console.log(confirmationResult);
      setConfirmation(confirmationResult);
      return confirmationResult;
      // ...
    })
    .catch((error) => {
      console.error(error);
    });
};

const successfulSignInPhone = (data) => {
  return new Promise(async (resolve) => {
    setPersistence(auth, browserLocalPersistence).then(() => {
      // console.log("State is now persisted");
    });

    const userAuth = {
      mobile: { id: data.mobile, uid: data.uid },
      unclaimed: false,
    };
    const user = {
      profile: {
        first_name: "",
        last_name: "",
        photo: "",
        email: "",
        is_email_verified: false,
        mobile: data.mobile,
        is_mobile_verified: true,
      },
      type: {
        brand: false,
        influencer: false,
        manager: false,
        end_user: false,
      },
      default_role: null,
    };
    const foundUser = await findUserByAuth("mobile", data.mobile);
    //console.log(foundUser);
    if (!foundUser) {
      const createdUser = await createUser(userAuth, user);
      return resolve({
        user: { ...createdUser, current_role: null },
        isNew: true,
      });
    } else {
      if (foundUser.auth.mobile.uid === null) {
        await updateDoc(doc(db, "users", foundUser.id), {
          auth: {
            ...foundUser.auth,
            mobile: { ...foundUser.auth.mobile, uid: data.uid },
            unclaimed: false,
          },
        });
        return resolve({
          user: {
            ...foundUser,
            current_role: foundUser.default_role,
            auth: {
              ...foundUser.auth,
              mobile: { ...foundUser.auth.mobile, uid: data.uid },
              unclaimed: false,
            },
          },
          isNew: false,
        });
      }
      return resolve({
        user: { ...foundUser, current_role: foundUser.default_role },
        isNew: false,
      });
    }
  });
};

const signUpWithGoogle = async () => {
  googleProvider.setCustomParameters({
    prompt: "select_account",
  });
  setPersistence(auth, browserLocalPersistence).then(() => {
    //console.log("State is now persisted");
  });
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const { displayName, photoURL, email, uid, accessToken, refreshToken } =
      res.user;
    const userAuth = { email: { id: email, uid: uid }, unclaimed: false };
    const user = {
      profile: {
        first_name: displayName.split(" ")[0] ?? "",
        last_name: displayName.split(" ")[1] ?? "",
        photo: photoURL ?? "",
        email: email,
        is_email_verified: true,
        mobile: "",
        is_mobile_verified: false,
      },
      type: {
        brand: false,
        influencer: false,
        manager: false,
        end_user: false,
      },
      default_role: null,
    };
    const foundUser = await findUserByAuth("email", email);

    if (!foundUser) {
      const createdUser = await createUser(userAuth, user);
      return { user: { ...createdUser, current_role: null }, isNew: true };
    } else {
      if (foundUser.auth.email.uid === null) {
        await updateDoc(doc(db, "users", foundUser.id), {
          auth: {
            ...foundUser.auth,
            email: { ...foundUser.auth.email, uid: uid },
            unclaimed: false,
          },
        });
        return {
          user: {
            ...foundUser,
            current_role: foundUser.default_role,
            auth: {
              ...foundUser.auth,
              email: { ...foundUser.auth.email, uid: uid },
              unclaimed: false,
            },
          },
          isNew: false,
        };
      }
      return {
        user: { ...foundUser, current_role: foundUser.default_role },
        isNew: false,
      };
    }
  } catch (err) {
    console.log(
      "File : In src/services/fb.js \n Function: signUpWithGoogle",
      err.message
    );
    alert(err.message);
  }
};

const updateRole = async (id, role, type) => {
  try {
    await updateDoc(doc(db, "users", id), {
      default_role: role,
      type: { ...type },
    });
  } catch (err) {
    console.log(
      "File : In src/services/fb.js \n Function: updateRole",
      err.message
    );
  }
};

const createManager = async (auth, manager) => {
  const managerDoc = await getDoc(doc(db, "managers", manager.id));
  const timestamp = Date.now();

  const doc_data = {
    timestamps: {
      created_on: timestamp,
      deleted_on: null,
      last_active: null,
      last_updated: null,
      blocked_on: null,
    },
    id: manager.id,
    auth: { ...auth },
    profile: { ...manager },
    unconfirmed_influencers: managerDoc.exists()
      ? { ...managerDoc.data().unconfirmed_influencers }
      : {},
    influencers: [],
    activity_summary: {
      giveaways: {
        tasks: { avg_completed: 0, total_created: 0, avg_created: 0 },
        live: 0,
        completed: 0,
        created: 0,
        total_participants: 0,
        revenue: { tokens_generated: 0, revenue_earned: 0 },
      },
    },
    wallet_summary: {},
    history: { giveaways: {}, influencers: {} },
  };
  try {
    await setDoc(doc(db, "managers", manager.id), { ...doc_data });
    await updateDoc(doc(db, "users", manager.id), {
      profile: { ...manager },
    });
    return new Promise((resolve) => {
      return resolve({ ...doc_data });
    });
  } catch (err) {
    alert("In fb.js, function:createManager during setDoc", err.message);
  }
};

const createInfluencer = async (auth, influencer) => {
  const influencerDoc = await getDoc(doc(db, "influencers", influencer.id));
  const timestamp = Date.now();

  const doc_data = {
    timestamps: {
      created_on: timestamp,
      deleted_on: null,
      last_active: null,
      last_updated: null,
      blocked_on: null,
    },
    id: influencer.id,
    auth: { ...auth },
    profile: { ...influencer },
    unconfirmed_managers: influencerDoc.exists()
      ? { ...influencerDoc.data().unconfirmed_managers }
      : {},
    managers: [],
    activity_summary: {
      giveaways: {
        tasks: { avg_completed: 0, total_created: 0, avg_created: 0 },
        live: 0,
        completed: 0,
        created: 0,
        total_participants: 0,
        revenue: { tokens_generated: 0, revenue_earned: 0 },
      },
    },
    wallet_summary: {},
    history: { giveaways: {}, managers: {} },
  };
  try {
    await setDoc(doc(db, "influencers", influencer.id), { ...doc_data });
    await updateDoc(doc(db, "users", influencer.id), {
      profile: { ...influencer },
    });
    return new Promise((resolve) => {
      return resolve({ ...doc_data });
    });
  } catch (err) {
    alert("In fb.js, function:createInfluencer during setDoc", err.message);
  }
};

const addInfluencer = async (provider, authId, influencer, managerId) => {
  const code_data = {
    code: "SP" + Date.now().toString(),
    timestamp: Date.now().toString(),
  };

  return new Promise(async (resolve) => {
    try {
      //Check if influencer exists in SocialPi
      const userFound = await findUserByAuth(provider, authId);
      const manager = await getDoc(doc(db, "managers", managerId));

      //If Influencer hasn't signed up on socialPi, create an unclaimed influencer profile
      if (!userFound) {
        const auth = { [provider]: { id: authId, uid: null }, unclaimed: true };
        const createdUser = await createUser(auth, {
          default_role: null,
          profile: {
            ...influencer,
            is_email_verified: false,
            is_mobile_verified: false,
          },
          type: {
            brand: false,
            end_user: false,
            manager: false,
            influencer: true,
          },
        });
        const createdInfluencer = await createInfluencer(createdUser.auth, {
          ...createdUser.profile,
          id: createdUser.id,
        });
        await updateDoc(doc(db, "influencers", createdInfluencer.id), {
          unconfirmed_managers: {
            [managerId]: {
              ...code_data,
              added_by_self: false,
            },
          },
        });

        await updateDoc(doc(db, "managers", managerId), {
          unconfirmed_influencers: {
            ...manager.data().unconfirmed_influencers,
            [createdInfluencer.id]: { ...code_data, added_by_self: true },
          },
        });
        return resolve({
          code: code_data.code,
          id: createdInfluencer.id,
        });
      }

      //If User profile already exists on SP then add an unconfirmed manager to it
      const id = userFound.id;
      if (id === managerId)
        return resolve({
          code: false,
          message: "Sorry you can not add your own self!",
        });

      //If User profile exists but role has not been selected as an influencer
      const influencerSnap = await getDoc(doc(db, "influencers", id));
      if (!influencerSnap.exists()) {
        const createdInfluencer = await createInfluencer(
          { ...userFound.auth, unclaimed: true },
          { id: userFound.id, ...userFound.profile }
        );
        await updateDoc(doc(db, "influencers", createdInfluencer.id), {
          unconfirmed_managers: {
            [managerId]: {
              ...code_data,
              added_by_self: false,
            },
          },
        });

        await updateDoc(doc(db, "managers", managerId), {
          unconfirmed_influencers: {
            ...manager.data().unconfirmed_influencers,
            [id]: { ...code_data, added_by_self: true },
          },
        });
        return resolve({ code: code_data.code, id: id });
      } else if (influencerSnap.data().managers.includes(managerId)) {
        return resolve({
          code: false,
          message: "Your are already connected with this influencer!",
        });
      } else {
        await updateDoc(doc(db, "influencers", id), {
          unconfirmed_managers: {
            ...influencerSnap.data().unconfirmed_managers,
            [managerId]: {
              ...code_data,
              added_by_self: false,
            },
          },
        });
        await updateDoc(doc(db, "managers", managerId), {
          unconfirmed_influencers: {
            ...manager.data().unconfirmed_influencers,
            [id]: { ...code_data, added_by_self: true },
          },
        });

        return resolve({ code: code_data.code, id: id });
      }
    } catch (err) {
      console.log(
        "File : In src/services/fb.js \n Function: addInfluencer",
        err.message
      );
    }
  });
};

const addManager = async (provider, authId, manager, influencerId) => {
  const code_data = {
    code: "SP" + Date.now().toString(),
    timestamp: Date.now().toString(),
  };

  return new Promise(async (resolve) => {
    try {
      //Check if manager exists in SocialPi
      const userFound = await findUserByAuth(provider, authId);
      const influencer = await getDoc(doc(db, "influencers", influencerId));

      //If manager hasn't signed up in socialPi create an unclaimed influencer profile
      if (!userFound) {
        const auth = { [provider]: { id: authId, uid: null }, unclaimed: true };
        const createdUser = await createUser(auth, {
          default_role: null,
          profile: {
            ...manager,
            is_email_verified: false,
            is_mobile_verified: false,
          },
          type: {
            brand: false,
            end_user: false,
            manager: true,
            influencer: false,
          },
        });
        const createdManager = await createManager(createdUser.auth, {
          ...createdUser.profile,
          id: createdUser.id,
        });
        await updateDoc(doc(db, "managers", createdManager.id), {
          unconfirmed_influencers: {
            [influencerId]: {
              ...code_data,
              added_by_self: false,
            },
          },
        });
        await updateDoc(doc(db, "influencers", influencerId), {
          unconfirmed_managers: {
            ...influencer.data().unconfirmed_managers,
            [createdManager.id]: { ...code_data, added_by_self: true },
          },
        });
        return resolve({ code: code_data.code, id: createdManager.id });
      }

      //If manager profile already exists on SP then add an unconfirmed manager to it
      const id = userFound.id;
      if (id === influencerId)
        return resolve({
          code: false,
          message: "Sorry you can not add your own self!",
        });
      const managerSnap = await getDoc(doc(db, "managers", id));
      if (!managerSnap.exists()) {
        const createdManager = await createManager(
          { ...userFound.auth, unclaimed: true },
          { id: userFound.id, ...userFound.profile }
        );
        await updateDoc(doc(db, "managers", createdManager.id), {
          unconfirmed_influencers: {
            [influencerId]: {
              ...code_data,
              added_by_self: false,
            },
          },
        });

        await updateDoc(doc(db, "influencers", influencerId), {
          unconfirmed_managers: {
            ...influencer.data().unconfirmed_managers,
            [id]: { ...code_data, added_by_self: true },
          },
        });
        return resolve({ code: code_data.code, id: id });
      } else if (managerSnap.data().influencers.includes(influencerId))
        return resolve({
          message: "You are already connected with this manager!",
          code: false,
        });
      else {
        await updateDoc(doc(db, "managers", id), {
          unconfirmed_influencers: {
            ...managerSnap.data().unconfirmed_influencers,
            [influencerId]: {
              ...code_data,
              added_by_self: false,
            },
          },
        });
        await updateDoc(doc(db, "influencers", influencerId), {
          unconfirmed_managers: {
            ...influencer.data().unconfirmed_managers,
            [id]: { ...code_data, added_by_self: true },
          },
        });
        return resolve({ code: code_data.code, id: id });
      }
    } catch (err) {
      console.log(
        "File : In src/services/fb.js \n Function: addManager",
        err.message
      );
    }
  });
};

const confirmInfluencer = (code, managerId) => {
  var ids_processed = 0;
  var unconfirmed_ids = 0;
  return new Promise(async (resolve) => {
    try {
      //console.log(managerId);
      await getDoc(doc(db, "managers", managerId)).then((managerSnapshot) => {
        const manager = managerSnapshot.data();
        //console.log(manager);
        if (Object.keys(manager.unconfirmed_influencers).length === 0)
          return resolve(false);
        Object.keys(manager.unconfirmed_influencers).forEach(
          async (influencerId) => {
            if (manager.unconfirmed_influencers[influencerId].code === code) {
              delete manager.unconfirmed_influencers[influencerId];
              await updateDoc(doc(db, "managers", managerId), {
                influencers: [...manager.influencers, influencerId],
                unconfirmed_influencers: { ...manager.unconfirmed_influencers },
              });

              const influencerSnapshot = await getDoc(
                doc(db, "influencers", influencerId)
              );
              const influencer = influencerSnapshot.data();
              delete influencer.unconfirmed_managers[managerId];
              await updateDoc(doc(db, "influencers", influencerId), {
                managers: [...influencer.managers, managerId],
                unconfirmed_managers: { ...influencer.unconfirmed_managers },
              });
              const { profile, id } = influencer;
              return resolve({
                profile: profile,
                id: id,
                status: "connected",
              });
            }
            ids_processed += 1;
            if (ids_processed === unconfirmed_ids) return resolve(false);
          }
        );
      });
    } catch (err) {
      console.log(
        "File : In src/services/fb.js \n Function: confirmInfluencer",
        err.message
      );
      return resolve(false);
    }
  });
};

const confirmManager = (code, influencerId) => {
  var ids_processed = 0;
  var unconfirmed_ids = 0;
  return new Promise(async (resolve) => {
    try {
      getDoc(doc(db, "influencers", influencerId)).then(
        (influencerSnapshot) => {
          const influencer = influencerSnapshot.data();

          if (Object.keys(influencer.unconfirmed_managers).length === 0)
            return resolve(false);
          unconfirmed_ids = Object.keys(influencer.unconfirmed_managers).length;

          Object.keys(influencer.unconfirmed_managers).forEach(
            async (managerId) => {
              if (influencer.unconfirmed_managers[managerId].code === code) {
                delete influencer.unconfirmed_managers[managerId];
                await updateDoc(doc(db, "influencers", influencerId), {
                  managers: [...influencer.managers, managerId],
                  unconfirmed_managers: { ...influencer.unconfirmed_managers },
                });

                const managerSnapshot = await getDoc(
                  doc(db, "managers", managerId)
                );
                const manager = managerSnapshot.data();
                delete manager.unconfirmed_influencers[influencerId];
                await updateDoc(doc(db, "managers", managerId), {
                  influencers: [...manager.influencers, influencerId],
                  unconfirmed_influencers: {
                    ...manager.unconfirmed_influencers,
                  },
                });

                const { profile, id } = manager;
                return resolve({
                  profile: profile,
                  id: id,
                  status: "connected",
                });
              }
              ids_processed += 1;
              if (ids_processed === unconfirmed_ids) return resolve(false);
            }
          );
        }
      );
    } catch (err) {
      console.log(
        "File : In src/services/fb.js \n Function: confirmManager",
        err.message
      );
      return resolve(false);
    }
  });
};

const getUnconfirmedInfluencers = (docIds) => {
  var idsProcessed = 0;
  var influencers = {};

  return new Promise(async (resolve) => {
    if (docIds.length === 0) return resolve({});
    const findInfluencersQuery = new query(
      collection(db, "influencers"),
      where("id", "in", [...docIds])
    );
    const unconfirmedDocs = await getDocs(findInfluencersQuery);
    if (unconfirmedDocs.docs.length === 0) return resolve({});
    unconfirmedDocs.docs.forEach((doc) => {
      influencers = {
        ...influencers,
        [doc.data().id]: {
          ...doc.data(),
          status: "not connected",
        },
      };
      idsProcessed += 1;
      if (idsProcessed === unconfirmedDocs.docs.length)
        return resolve(influencers);
    });
  });
};

const getUnconfirmedManagers = async (docIds) => {
  var idsProcessed = 0;
  var managers = {};
  return new Promise((resolve) => {
    if (docIds.length === 0) return resolve({});
    docIds.forEach(async (id) => {
      await getDoc(doc(db, "managers", id)).then((manager) => {
        managers = {
          ...managers,
          [manager.data().id]: {
            ...manager.data(),
            status: "not connected",
          },
        };

        idsProcessed += 1;
        if (idsProcessed === docIds.length) return resolve(managers);
      });
    });
  });
};

const getSelfManager = async (id) => {
  try {
    const manager = await getDoc(doc(db, "managers", id));
    if (!manager.exists()) return {};
    return { self: { ...manager.data() } };
  } catch (err) {
    console.log(
      "File : In src/services/fb.js \n Function: getSelfManager",
      err.message
    );
  }
};

const getManagers = async (id) => {
  try {
    const managers = {};
    const q = query(
      collection(db, "managers"),
      where("influencers", "array-contains", id)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((manager) => {
      managers[`${manager.data().id}`] = {
        ...manager.data(),
        status: "connected",
      };
    });
    return managers;
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: getManagers",
      err.message
    );
  }
};

export const updateProfileFields = async ({ userId, role, data }) => {
  const fields = Object.keys(data);
  let update_object = {};
  fields.map((field) => {
    update_object = { ...update_object, [`profile.${field}`]: data[field] };
    return 0;
  });

  try {
    await updateDoc(doc(db, "users", userId), {
      ...update_object,
      [`timestamps.updated_on`]: Date.now(),
    });
    await updateDoc(doc(db, `${role}s`, userId), {
      ...update_object,
      [`timestamps.updated_on`]: Date.now(),
    });
    return { success: true };
  } catch (err) {
    console.log(err.message);
    return { success: false, message: err.message };
  }
};

const saveManagerProfile = async (uid, key, data) => {
  try {
    await updateDoc(doc(db, "managers", `${uid}`), { [`${key}`]: data });
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: saveManagerProfile",
      err.message
    );
  }
};

const getInfluencers = async (id) => {
  try {
    const influencers = {};
    const q = query(
      collection(db, "influencers"),
      where("managers", "array-contains", id)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.docs.forEach((influencer) => {
      influencers[`${influencer.data().id}`] = {
        ...influencer.data(),
        status: "connected",
      };
    });
    return influencers;
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: getInfluencers",
      err.message
    );
  }
};

const getSelfInfluencer = async (id) => {
  try {
    const influencer = await getDoc(doc(db, "influencers", id));
    if (!influencer.exists()) return {};
    return { self: { ...influencer.data() } };
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: getSelfInfluencer",
      err.message
    );
  }
};

const doesAliasNameExist = async (influencer, alias) => {
  const aliasNameQuery = query(
    collection(db, "aliases"),
    where("influencer", "==", influencer),
    where("name", "==", alias),
    where("status", "==", "active")
  );
  const aliasSnaps = await getDocs(aliasNameQuery);
  if (aliasSnaps.docs.length === 0) return false;
  return true;
};

const createAlias = async (influencer, data) => {
  const isExisting = await doesAliasNameExist(influencer, data.alias);
  if (isExisting)
    return { error: true, message: "Alias already exists for influencer" };
  else {
    const aliasRef = doc(collection(db, "aliases"));
    const timestamp = Date.now();
    const alias_doc = {
      timestamps: {
        created_on: timestamp,
        deleted_on: null,
        last_active: null,
        last_updated: null,
        blocked_on: null,
      },
      id: aliasRef.id,
      influencer: influencer,
      name: data.alias,
      photo: "",
      managers: [],
      permissions: {},
      platforms: {
        youtube: {},
        instagram: {},
        facebook: {},
        twitter: {},
        telegram: {},
        disocrd: {},
        custom: {},
      },
      status: "active",
    };
    return new Promise(async (resolve) => {
      try {
        await setDoc(aliasRef, { ...alias_doc });
        await updateDoc(doc(db, "influencers", influencer), {
          aliases: arrayUnion(data.alias),
        });
        return resolve(alias_doc);
      } catch (err) {
        console.error(
          "File : In src/services/fb.js \n Function: createAlias",
          err.message
        );
      }
    });
  }
};

const saveAliasDetails = async (id, data) => {
  try {
    await updateDoc(doc(db, "aliases", id), {
      ...data,
      [`timestamps.updated_on`]: Date.now(),
    });
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: saveAliasDetails",
      err.message
    );
  }
};

const updateAliasInformation = async (id, key, value) => {
  try {
    await updateDoc(doc(db, "aliases", id), {
      [`${key}`]: value,
      [`timestamps.updated_on`]: Date.now(),
    });
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: updateAliasInformation",
      err.message
    );
  }
};

const saveAliasPlatform = async (id, platform, data) => {
  try {
    await updateDoc(doc(db, "aliases", id), {
      [`platforms.${platform}.${data.id}`]: { ...data },
      [`timestamps.updated_on`]: Date.now(),
    });
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: saveAliasDetails",
      err.message
    );
  }
};

const deleteAliasDetails = async (id) => {
  const timestamp = Date.now();
  let deleted_docs = { alias: {}, giveaways: [] };

  try {
    const alias = await getDoc(doc(db, "aliases", id));
    await updateDoc(doc(db, "influencers", alias.data().influencer), {
      aliases: arrayRemove(alias.data().name),
      [`timestamps.updated_on`]: timestamp,
    });
    await updateDoc(doc(db, "aliases", id), {
      status: "deleted",
      [`timestamps.deleted_on`]: timestamp,
      [`timestamps.updated_on`]: timestamp,
    });
    deleted_docs.alias = alias.data();
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: deleteAliasDetails",
      err.message
    );
  }

  try {
    const giveaways_collection_ref = collection(db, "giveaways");
    const draft_query = query(
      giveaways_collection_ref,
      where("alias", "==", id),
      where("status", "==", "drafts")
    );
    const docSnaps = await getDocs(draft_query);
    docSnaps.docs.forEach(async (docSnap) => {
      const docRef = doc(db, "giveaways", docSnap.data().id);
      await updateDoc(docRef, {
        status: "deleted",
        [`timestamps.deleted_on`]: timestamp,
        [`timestamps.updated_on`]: timestamp,
      });
      //console.log("Here are docs", docSnap.data());
      //giveawayDocs = [...giveawayDocs, { ...docSnap.data() }];
      deleted_docs.giveaways = [
        ...deleted_docs.giveaways,
        { ...docSnap.data() },
      ];
    });
  } catch (err) {
    console.log(err.message);
  }
  return {
    deleted_alias: deleted_docs.alias,
    deleted_giveaways: deleted_docs.giveaways,
  };
};

export const discardAlias = async (id) => {
  const desertRef = ref(storage, `profile_uploads/aliases/${id}/alias_photo`);

  // Delete the file
  deleteObject(desertRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      console.log(error.message);
    });
  const alias_ref = doc(db, "aliases", id);
  await deleteDoc(alias_ref);
};

const getAliasDetails = async (id, parameter, role) => {
  var ids_processed = 0;
  return new Promise(async (resolve) => {
    try {
      const aliases = {};
      const q = query(collection(db, "aliases"), where(parameter, "==", id));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0) return resolve({});
      else
        querySnapshot.docs.forEach((alias) => {
          if (alias.data().status !== "deleted") {
            var key =
              role === roles.influencer ? "self" : alias.data().influencer;
            if (aliases[key] === undefined)
              aliases[key] = {
                [alias.data().id]: { ...alias.data() },
              };
            else
              aliases[key][alias.data().id] = {
                ...alias.data(),
              };
          }
          ids_processed += 1;
          if (ids_processed === querySnapshot.docs.length)
            return resolve(aliases);
        });
    } catch (err) {
      console.error(
        "File : In src/services/fb.js \n Function: getAliasDetails",
        err.message
      );
    }
  });
};

/*##########################################################
######################### GIVEAWAYS ########################
###########################################################*/
export const getRewards = (rewards) => {
  var current_rewards = {};
  var fetched = 0;
  return new Promise(async (resolve) => {
    if (rewards.length === 0) return resolve(current_rewards);
    const rewardQuery = new query(
      collection(db, "rewards"),
      where("id", "in", [...rewards])
    );
    const rewardDocs = await getDocs(rewardQuery);
    if (rewardDocs.docs.length === 0) return resolve(current_rewards);
    rewardDocs.docs.forEach(async (reward) => {
      current_rewards = {
        ...current_rewards,
        [reward.data().id]: { ...reward.data() },
      };
      fetched += 1;
      if (rewardDocs.docs.length === fetched) return resolve(current_rewards);
    });
  });
};

export const getGiveawayDoc = async (id) => {
  const giveaway_ref = doc(db, "giveaways", id);
  const giveaway_doc = await getDoc(giveaway_ref);
  if (!giveaway_doc.exists()) return false;
  else return giveaway_doc.data();
};

const getGiveaways = async (id, parameter) => {
  var giveaways = {
    drafts: {},
    scheduled: {},
    completed: {},
    running: {},
    ended: {},
  };

  var fetched = 0;
  const q =
    parameter === "manager"
      ? query(collection(db, "giveaways"), where("creator", "==", id))
      : query(collection(db, "giveaways"), where(parameter, "==", id));
  const giveawaySnapshot = await getDocs(q);
  return new Promise((resolve) => {
    if (giveawaySnapshot.docs.length === 0) return resolve(giveaways);

    giveawaySnapshot.docs.forEach(async (giveawayDoc) => {
      const { id, status, rewards, end_date, end_time } = giveawayDoc.data();
      const current_rewards = await getRewards(rewards);
      if (
        status === "running" &&
        Date.parse(new Date(end_date + " " + end_time)) < Date.now()
      ) {
        await updateDoc(doc(db, "giveaways", id), { status: "ended" });
        giveaways.ended = {
          ...giveaways.ended,
          [id]: { ...giveawayDoc.data(), rewards: { ...current_rewards } },
        };
      }
      giveaways[status] = {
        ...giveaways[status],
        [id]: { ...giveawayDoc.data(), rewards: { ...current_rewards } },
      };
      fetched += 1;
      if (fetched === giveawaySnapshot.docs.length) return resolve(giveaways);
    });
  });
};

const createGiveawayDraft = async (data) => {
  /* data should contain : 
  created_by: role of creator,
  creator: id of creator,
  influencer: id of the influencer for which giveaway is created for,
  alias: id of the alias created for,
  alias_name: name of the alias,
  alias_photo: image of the alias,
  managers: [],
   */
  const giveawayRef = doc(collection(db, "giveaways"));
  const draft = {
    ...data,
    id: giveawayRef.id,
    created_on: Date.now(),
    participants: 0,
    completed_by: 0,
    completed_first_by: null,
    time_of_first_completion: 0,
    ad_views: 0,
    winners: {},
    rewards: [],
    tasks: {
      youtube: { account: {}, post_activity: {} },
      instagram: { account: {}, post_activity: {}, new_post: {} },
      twitter: { account: {}, post_activity: {}, new_post: {} },
      facebook: { account: {}, post_activity: {}, new_post: {} },
      telegram: { account: {} },
      discord: { account: {} },
      custom: {},
    },
    status: giveaway_status.drafts,
    global_reward_status: "pending winner generation for all rewards",
    timestamps: setTimestamps(),
  };
  try {
    await setDoc(doc(db, "giveaways", giveawayRef.id), {
      ...draft,
    });
    return new Promise((resolve) => {
      return resolve(draft);
    });
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: createGiveawayDraft",
      err.message
    );
  }
};

const deleteGiveawayDraft = (id) => {
  return new Promise(async (resolve) => {
    const giveaway = await getDoc(doc(db, "giveaways", id));
    const { rewards } = giveaway.data();

    rewards.forEach((rewardId) => {
      deleteDoc(doc(db, "rewards", rewardId));
    });
    await deleteDoc(doc(db, "giveaways", id));
    return resolve(id);
  });
};

const getRewardRef = () => {
  const rewardRef = doc(collection(db, "rewards"));
  return rewardRef.id;
};

const createReward = async (type, data, giveawayId) => {
  logging({
    message: "Write Data received in firebase function",
    data: { type: type, data: data, giveawayId: giveawayId },
    from_file: "fb.js",
    from_function: "createReward",
  });
  const reward = {
    giveaway: giveawayId,
    id: data.id,
    type: type,
    dist_details: {
      status: "pending winner generation",
      mode: null,
      winners: [],
    },
    ...data,
  };

  try {
    await setDoc(doc(db, "rewards", data.id), {
      ...reward,
    });
    await updateDoc(doc(db, "giveaways", giveawayId), {
      rewards: arrayUnion(data.id),
    });
    return reward;
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: createReward",
      err.message
    );
  }
};

const updateReward = async (id, data) => {
  try {
    await updateDoc(doc(db, "rewards", id), { ...data });
  } catch (err) {
    console.error(err.message);
  }
};

const deleteReward = async (id, giveawayId) => {
  try {
    await deleteDoc(doc(db, "rewards", id));
    await updateDoc(doc(db, "giveaways", giveawayId), {
      rewards: arrayRemove(id),
    });
  } catch (err) {
    console.error(err.message);
  }
};

const saveGiveawayDraft = async (id, data) => {
  try {
    await updateDoc(doc(db, "giveaways", id), { ...data });
    return id;
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: saveGiveawayDraft",
      err.message
    );
  }
};

const saveGiveawayPlatformTasks = async (id, platform, tasks) => {
  try {
    await updateDoc(doc(db, "giveaways", id), {
      [`tasks.${platform}`]: { ...tasks },
    });
    return id;
  } catch (err) {
    console.error(
      "File : In src/services/fb.js \n Function: saveGiveawayDraft",
      err.message
    );
  }
};

const checkBrandCustomization = async ({ id }) => {
  //console.log(id);
  const brand_custom_query = new query(
    collection(db, "brand_customized_tasks"),
    where("code", "==", id)
  );
  const docSnaps = await getDocs(brand_custom_query);
  if (docSnaps.docs.length === 0) return false;
  if (docSnaps.docs.length !== 0) return true;
};

const saveTaskStatistics = async ({ giveaway, taskId, stats }) => {
  try {
    await updateDoc(doc(db, "giveaways", giveaway), {
      [`task_analytics_before.${taskId}`]: { ...stats },
    });
  } catch (err) {
    console.error("File: In src/serives/fb.js \n Function: saveTaskStatistics");
  }
};

const getWinnersByTasks = async (giveawayId, tasks) => {
  let candidates = [];
  let candidates_processed = 0;
  const winnerQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId),
    where("tasks_completed", "array-contains-any", tasks),
    where("is_winner", "==", false)
  );

  const winnersSnapshot = await getDocs(winnerQuery);
  return new Promise((resolve) => {
    if (winnersSnapshot.docs.length === 0) return resolve([]);
    winnersSnapshot.docs.forEach((candidate) => {
      const { tasks_completed } = candidate.data();

      if (intersection(tasks_completed, tasks).length === tasks.length) {
        candidates = [...candidates, candidate];
      }
      candidates_processed += 1;
      if (candidates_processed === winnersSnapshot.docs.length) {
        //console.log(candidates, "From getWinnerByTasks");
        return resolve(candidates);
      }
    });
  });
};

const getWinnersRandomly = async (giveawayId) => {
  const winnerQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId),
    where("tickets_earned", ">", 0),
    where("is_winner", "==", false)
  );
  const winnersSnapshot = await getDocs(winnerQuery);
  return winnersSnapshot.docs;
};

const getWinnersByNumberofTickets = async (giveawayId, min_tickets) => {
  const winnerQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId),
    where(
      "tickets_earned",
      ">=",
      parseInt(min_tickets),
      where("is_winner", "==", false)
    )
  );
  const winnersSnapshot = await getDocs(winnerQuery);
  return winnersSnapshot.docs;
};

const getWinnersByRank = async (giveawayId, no_of_winners) => {
  const winnerQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId),
    where("rank", "<=", no_of_winners),
    where("is_winner", "==", false)
  );
  const winnersSnapshot = await getDocs(winnerQuery);
  return winnersSnapshot.docs;
};

const getWinnersByTime = async (giveawayId, time) => {
  const winnerQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId),
    where("completed_at", "<=", time),
    where("is_winner", "==", false)
  );
  const winnersSnapshot = await getDocs(winnerQuery);
  return winnersSnapshot.docs;
};

const findWinnerDetails = (number, winnersSnapshot) => {
  let winners = [];
  let winners_processed = 0;
  return new Promise((resolve) => {
    const winnerDocs = randomizeCandidates(number, winnersSnapshot);
    //console.log("Randomized winner participation is", winnerDocs);
    if (winnerDocs.length === 0) return resolve([]);
    winnerDocs.forEach(async (participation) => {
      const userDocSnap = await getDoc(
        doc(db, "end_users", participation.data().participant)
      );
      //console.log(userDocSnap.data());
      const {
        id,
        profile: { first_name, last_name, mobile, email },
      } = userDocSnap.data();
      const winner = {
        id: id,
        first_name: first_name,
        last_name: last_name,
        mobile: mobile,
        email: email,
        accounts_used: userDocSnap.data().accounts_used ?? {},
        address: userDocSnap.data().profile.addresses?.main ?? {},
      };
      winners = [...winners, winner];
      winners_processed += 1;
      if (winners_processed === winnerDocs.length) {
        //console.log("Winner details are", winners);
        return resolve(winners);
      }
    });
  });
};

const updateGlobalRewardStatus = async ({ giveawayId, status }) => {
  await updateDoc(doc(db, "giveaways", giveawayId), {
    global_reward_status: status,
  });
};

const updateRewardDistStatus = async ({ rewardId, status, mode }) => {
  const time_key =
    status === "pending announcement"
      ? "generated"
      : status === "pending distribution"
      ? "announced"
      : "distributed";
  await updateDoc(doc(db, "rewards", rewardId), {
    [`dist_details.status`]: status,
    [`dist_details.mode`]: mode,
    [`dist_details.${time_key}`]: Date.now(),
  });
};

const updateWinnersInReward = async ({ rewardId, winners, criteria }) => {
  await updateDoc(doc(db, "rewards", rewardId), {
    [`dist_details.generated`]: Date.now(),
    [`dist_details.status`]: "pending announcement",
    [`dist_details.winners`]: [...winners],
    [`dist_details.criteria`]: criteria,
  });
};

const updateRewardDisributed = async ({ rewardId, redemption }) => {
  await updateDoc(doc(db, "rewards", rewardId), {
    [`dist_details.winners`]: [...redemption],
  });
};

const resetWinnerStateInReward = async ({ rewardId }) => {
  await updateDoc(doc(db, "rewards", rewardId), {
    dist_details: {
      status: "pending winner generation",
      winners: [],
      mode: null,
    },
  });
};

const resetWinnerStateInGiveaway = async ({ giveawayId }) => {
  await updateDoc(doc(db, "giveaways", giveawayId), {
    winners: {},
  });
};

const resetParticipantWinning = ({ winners = [], giveawayId = "" }) => {
  winners.forEach(async (winner) => {
    await updateDoc(
      doc(db, "participation_data", winner.id + "_" + giveawayId),
      {
        is_winner: false,
        reward_won: null,
      }
    );
  });
};

const updateGiveawayWinners = async ({ winners, reward, giveawayId }) => {
  let winner_details = [];
  //console.log(winners);

  winners.forEach(async (winner) => {
    /*   winner_details = [
        ...winner_details,
        {
          id: winner.id,
          first_name: winner.first_name,
          last_name: winner.last_name,
          email: winner.email,
          phone: winner.phone,
           accounts_used: { ...winner.accounts_used },
          address: { ...winner.address }, 
        },
      ]; */
    const docId = winner.id + "_" + giveawayId;
    await updateDoc(doc(db, "participation_data", docId), {
      is_winner: true,
      reward_won: {
        title: reward.title,
        image: reward.image,
        id: reward.id,
        details: reward.details,
        type: reward.type,
      },
    });
  });
};

const addCouponToWinner = async ({ winnerId, giveawayId, coupon_won }) => {
  const docId = winnerId + "_" + giveawayId;

  await updateDoc(doc(db, "participation_data", docId), {
    [`reward_won.coupon`]: {
      ...coupon_won,
    },
  });
};

export const addProductToWinner = async ({
  winnerId,
  giveawayId,
  product_won,
}) => {
  const docId = winnerId + "_" + giveawayId;

  await updateDoc(doc(db, "participation_data", docId), {
    [`reward_won.product`]: {
      ...product_won,
    },
  });
};

export const addRewardToWinner = async ({
  winnerId,
  giveawayId,
  reward_won,
  reward_type,
}) => {
  const docId = winnerId + "_" + giveawayId;

  await updateDoc(doc(db, "participation_data", docId), {
    [`reward_won.${reward_type}`]: {
      ...reward_won,
    },
  });
};

const samplQueryTicketCriteria = async (giveawayId) => {
  const q = query(
    collection(db, "participation_data"),
    where(`${giveawayId}.tickets_earned`, ">=", 320)
  );
  //console.log("IN WINNER SELECTION");
  const snaps = await getDocs(q);
  let winner_details = [];
  snaps.docs.forEach(async (snap) => {
    //console.log(snap.id);
    const winner = await getDoc(doc(db, "end_users", snap.id));
    winner_details = [...winner_details, { ...winner.data().profile }];
    //console.log(winner_details);
  });
};

/*############################################################################ 
########################## BRANDS FUNCTIONS ##################################
##############################################################################*/
const signUpBrandWthGoogle = async () => {
  googleProvider.setCustomParameters({
    prompt: "select_account",
  });
  setPersistence(auth, browserSessionPersistence).then(() => {
    console.log("State is now persisted");
  });

  try {
    const res = await signInWithPopup(auth, googleProvider);

    const { displayName, photoURL, email, uid, accessToken, refreshToken } =
      res.user;

    const userAuth = { email: { id: email, uid: uid } };
    const userFound = await findUserByAuth("email", email);

    if (!userFound) {
      const user = {
        profile: {
          first_name: displayName.split(" ")[0],
          last_name: displayName.split(" ")[1],
          email: email,
          is_email_verified: true,
          mobile: "",
          is_mobile_verified: false,
          photoURL: photoURL,
        },
        type: {
          brand: true,
          influencer: false,
          manager: false,
          end_user: false,
        },
        default_role: "brand",
      };
      const createdUser = await createUser(userAuth, user);
      return { user: { ...createdUser }, isNew: true };
    } else return { user: { ...userFound }, isNew: false };
  } catch (err) {
    console.error("In fb.js, function: signUpBrandWithGoogle");
  }
};

const createBrand = async (auth, brand) => {
  await setDoc(doc(db, "brands", brand.id), {
    auth: { ...auth },
    profile: { ...brand.profile },
    is_brand_verified: false,
  });
};

const startSocialConnection = async ({ userId, aliasId, platform, tokens }) => {
  await updateDoc(doc(db, "users", userId), {
    connection_data: {
      platform: platform,
      aliasId: aliasId,
      tokens: { ...tokens },
    },
  });
};

const getStartedSocialConnection = async ({ userId }) => {
  const influencerDoc = await getDoc(doc(db, "users", userId));
  return influencerDoc.data().connection_data;
};

const completeSocialConnection = async ({
  userId,
  aliasId,
  platform,
  tokens,
  account,
}) => {
  await updateDoc(doc(db, "users", userId), {
    connection_data: null,
  });
  await updateDoc(doc(db, "aliases", aliasId), {
    [`platforms.${platform}.${account.handle}`]: {
      tokens: tokens,
      ...account,
    },
  });
};

export {
  auth,
  db,
  storage,
  findUserByAuth,
  uploadImageToFirebase,
  fetchData,
  signUpWithGoogle,
  signUpWithPhone,
  successfulSignInPhone,
  signUpBrandWthGoogle,
  createManager,
  createInfluencer,
  createBrand,
  getSelfManager,
  saveManagerProfile,
  createAlias,
  saveAliasDetails,
  startSocialConnection,
  getStartedSocialConnection,
  completeSocialConnection,
  updateAliasInformation,
  getAliasDetails,
  deleteAliasDetails,
  saveAliasPlatform,
  getInfluencers,
  getSelfInfluencer,
  getManagers,
  updateRole,
  addManager,
  addInfluencer,
  confirmInfluencer,
  confirmManager,
  getUnconfirmedInfluencers,
  getUnconfirmedManagers,
  getGiveaways,
  createGiveawayDraft,
  deleteGiveawayDraft,
  saveGiveawayPlatformTasks,
  checkBrandCustomization,
  getRewardRef,
  createReward,
  saveGiveawayDraft,
  updateReward,
  deleteReward,
  updateWinnersInReward,
  updateRewardDistStatus,
  updateRewardDisributed,
  updateGlobalRewardStatus,
  getWinnersByTasks,
  getWinnersByNumberofTickets,
  getWinnersRandomly,
  getWinnersByRank,
  findWinnerDetails,
  samplQueryTicketCriteria,
  updateGiveawayWinners,
  addCouponToWinner,
  resetWinnerStateInReward,
  resetWinnerStateInGiveaway,
  resetParticipantWinning,
};
